import "react-toastify/dist/ReactToastify.css";

const containerBoxShadow = {
  borderRadius: "8px",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  flexGrow:'1',

};

const headerStyles = {
  backgroundColor: "#0078d4",
  height: "60px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  padding: "0 10%",
  position:"unset"
};

const tollbarStyles = {
  backgroundColor: "#0078d4",
  height: "100vh",
  width: "70px",
  paddingTop: "60px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  position:"unset"

};

const imageSize = {
  width: "200px",
  height: "100px",
};

export const imageSizeNextage = {
  width: "200px",
  height: "45px",
  marginRight: '30px'
};

const logoSize = {
  width: "90%",
  height: "60px",
  transition: "all 0.2s",
};

const hoverStyle = {
  transform: "scale(0.8)",
  backgroundColor: "#00579B",
  borderRadius: "50%",
};

const  modalOpen = {
  backgroundColor: "red",
  overflow: "hidden",
  position: "unset",
  zIndex: "-1"
}

export {
  containerBoxShadow,
  headerStyles,
  imageSize,
  tollbarStyles,
  logoSize,
  hoverStyle,
  modalOpen
};
