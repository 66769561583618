import React, { useState } from "react";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import api from "../../services/api";
import ErrorIcon from '@mui/icons-material/Error';
import { Body } from "../../components/Body";
import { useNavigate } from "react-router-dom";
import { GoogleMap, useLoadScript, MarkerF, Polyline, InfoWindowF } from '@react-google-maps/api';
import { Row } from "react-bootstrap";

interface CheckProps {
  itemsCheck: string[];
  setItemsCheck: React.Dispatch<React.SetStateAction<string[]>>;
}

export const Station: React.FC<CheckProps> = ({ itemsCheck, setItemsCheck }) => {
  const [stationName, setStationName] = useState<string>('');
  const [local, setLocal] = useState<string>('');
  const [latitude, setLatitude] = useState<string>('');
  const [longitude, setLongitude] = useState<string>('');
  const [data, setData] = useState<string>('');
  const [isLocationDefined, setIsLocationDefined] = useState(false);
  const [showMap, setShowMap] = useState<boolean>(true);
  const inputName: string[] = ["Nome da Estação", "Local", "Latitude", "Longitude"];
  const navigate = useNavigate();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyA9MjROBJTSX0CPsWOaTtYjvbFXORbLRGU',
  });

  function removeAccents(str: string) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  const handleLocationChange = async () => {
    const responseLocation = await api.post("/api/searchLocal", {
      local: local
    });

    if (responseLocation.data === "") {
      toast.error("Insira um endereço válido");
    } else {
      const latitude = responseLocation.data.latitude;
      const longitude = responseLocation.data.longitude;
      setLatitude(latitude);
      setLongitude(longitude);
      setIsLocationDefined(true);
      setShowMap(true);
    }
  };

  const Station = async () => {
    try {
      const response = await api.post("/api/addStation", {
        name: stationName,
        local: removeAccents(local)
      });

      if (response.status === 200) {
        setStationName("");
        setLocal("");
        setData("");
        setLatitude("");
        setLongitude("");
        setShowMap(false);

        toast.success("Cadastro de Estação realizado com sucesso!", {
          position: toast.POSITION.TOP_CENTER
        });

      } else {
        toast.error("Erro ao cadastrar uma estação, tente novamente!");
      }



    } catch (error: any) {
      console.error('Erro forte', error);
    }
  };

  const positions: any = {
    latitude: latitude,
    longitude: longitude
  }

  const onMapClick = (event: any) => {
    setLatitude(event.latLng.lat().toString());
    setLongitude(event.latLng.lng().toString());
  };

  const mapRender = () => (
    <GoogleMap
      mapContainerStyle={{ width: '45%', height: '400px', maxWidth: '45%' }}
      center={{ lat: parseFloat(latitude) || 0, lng: parseFloat(longitude) || 0 }}
      zoom={15}
      onClick={onMapClick}
    >
      <MarkerF position={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }} />
    </GoogleMap>
  );

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", width: '100%', borderColor: '#efefef' }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Row>

          </Row>
          <TextField
            sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
            label={`${inputName[0].charAt(0).toUpperCase()}${inputName[0].slice(1)}`}
            size='small'
            value={stationName}
            onChange={(e) => setStationName(e.target.value)}
          />
          <Box>
            <TextField
              sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '10px' }}
              label={`${inputName[1].charAt(0).toUpperCase()}${inputName[1].slice(1)}`}
              size='small'
              value={local}
              onChange={(e) => setLocal(e.target.value)}
            />

            <button
              onClick={handleLocationChange}
              type="button"
              className="btn btn-primary"
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#192B9F'}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#111c5d'}
              style={{
                backgroundColor: '#111c5d',
                width: 100,
                height: 40,
                borderRadius: 5,
                bottom: 10,
                right: 10,
                padding: 0,
                alignSelf: 'flex-end',
                fontSize: 20,
                marginLeft: 10
              }}
            >
              <p style={{ fontSize: 16, margin: 0 }}>BUSCAR</p>
            </button>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '600px' }}>
              <ErrorIcon sx={{ color: '#A0A0A0', fontSize: '17px', marginBottom: '20px' }} />
              <span style={{ display: 'flex', alignItems: 'center', marginLeft: '5px', flexDirection: 'column' }}>
                <h5 style={{ fontSize: '13px', color: '#A0A0A0', margin: 0, marginBottom: '20px' }}>Não utilize CEPs para a busca da localização</h5>
              </span>
            </Box>
          </Box>
          {isLocationDefined && isLoaded && showMap && (
            <>
              <TextField
                sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
                label={`${inputName[2].charAt(0).toUpperCase()}${inputName[2].slice(1)}`}
                size='small'
                value={latitude} />
              <TextField
                sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
                label={`${inputName[3].charAt(0).toUpperCase()}${inputName[3].slice(1)}`}
                size='small'
                value={longitude} />
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {mapRender()}
              </Box>
            </>

          )}

          <Box sx={{ display: "flex", justifyContent: 'flex-end', width: '100%', marginY: '20px' }}>
            <button
              onClick={Station}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#122079'}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#111c5d'}
              type="button"
              className="btn btn-primary"
              style={{
                backgroundColor: '#111c5d',
                width: 100,
                height: 40,
                borderRadius: 5,
                bottom: 10,
                right: 10,
                padding: 0,
                alignSelf: 'flex-end',
                fontSize: 20,
                border: 'none'
              }}
            >
              <p style={{ fontSize: 16, margin: 0 }}>SALVAR</p>

            </button>
            <button
              onClick={() => navigate(`/details/equipamentos`)}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#122079'}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#111c5d'}
              type="button"
              className="btn btn-primary"
              style={{
                backgroundColor: '#111c5d',
                width: 100,
                height: 40,
                borderRadius: 5,
                bottom: 10,
                right: 10,
                padding: 0,
                alignSelf: 'flex-end',
                fontSize: 20,
                border: 'none',
                marginLeft: 10
              }}
            >
              <p style={{ fontSize: 16, margin: 0 }}>PRÓXIMO</p>
            </button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
