import { useState, useEffect, SetStateAction } from "react";
import { Box, MenuItem, IconButton, Button, darken } from "@mui/material";
import TextField from '@mui/material/TextField';
import { ToastContainer, toast } from "react-toastify";
import ErrorIcon from '@mui/icons-material/Error';
import api from "../../services/api";
import { useNavigate } from "react-router-dom";
import { JSX } from "react/jsx-runtime";
import Modal from "react-bootstrap/esm/Modal";

interface CheckProps {
    itemsCheck: string[];
    setItemsCheck: React.Dispatch<React.SetStateAction<string[]>>;
}

export const Equipment: React.FC<CheckProps> = ({ itemsCheck, setItemsCheck }) => {
    const [itemList, setItemList] = useState<string[]>([])
    const [resetInputs, setResetInputs] = useState(false);
    const [selectedStationId, setSelectedStationId] = useState<string>('');
    const [selectedTypeId, setSelectedTypeId] = useState<string>('');
    const [equipmentName, setEquipmentName] = useState<string>('');
    const [code, setCode] = useState<string>('');
    const [pdf, setPdf] = useState<string>('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [latitude, setLatitude] = useState<string>('');
    const [longitude, setLongitude] = useState<string>('');
    const [location, setLocation] = useState<string>('');
    const [observation, setObservation] = useState<string>('');
    const [curiosity, setCuriosity] = useState<string>('');
    const [items, setItems] = useState<string>('');
    const [stations, setStations] = useState<any[]>([]);
    const [equipmentType, setEquipmentType] = useState<any[]>([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [base64String, setBase64String] = useState<string>('');
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar a abertura e o fechamento do modal
    const inputName: string[] = ["Nome", "Codigo", "Tipo", "Estação", "Latitude", "Longitude", "Observações", "Curiosidades", "Itens", "Pdf"];
    const navigate = useNavigate();

    async function Equipment() {
        try {
            const responseEquipment = await api.post("/api/addEquipment", {
                name: equipmentName,
                code: code,
                latitude: latitude,
                longitude: longitude,
                typeId: selectedTypeId,
                stationId: selectedStationId,
                obs: observation,
                curiosity: curiosity,
                address: location,
                pdf: pdf
            });
            const equipmentId = responseEquipment.data.id;

            const responseItems = await api.post("/api/addItem", itemList);
            

            const itemEquipArray = responseItems.data.map((item: { id: number, name: string }) => ({
                equipment_id: equipmentId,
                item_id: item.id,
            }));


            const responseItemEquip = await api.post("/api/addItemEquip", itemEquipArray);

            if (responseEquipment.status === 200 && responseItems.status === 200 && responseItemEquip.status === 200) {
                setEquipmentName("")
                setCode("")
                setLatitude("")
                setLongitude("")
                setSelectedStationId("")
                setSelectedTypeId("")
                setPdf("")
                setObservation("")
                setCuriosity("")
                setLocation("")
                setItemList([])
                setItems('')
                setSelectedFile(null)


                toast.success("Equipamento cadastrado com sucesso!");
            } else {
                toast.error("Erro ao cadastrar um equipamento, por favor tente novamente!");
            }


        } catch (error: any) {
            console.error('Erro forte', error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseEquipmentTypes = await api.get<{ id: string, name: string }[]>(`/api/getAllTypes`);
                const responseStation = await api.get<{ id: string, name: string }[]>(`/api/getAllStation`);
                

                setStations(responseStation.data);
                setEquipmentType(responseEquipmentTypes.data);
            } catch (error: any) {
                console.error('Error fetching equipment data:', error);
            }
        };

        fetchData();
    }, [resetInputs]);

    const handleStationChange = async (e: React.ChangeEvent<{ value: unknown }>, station_id: string) => {
        const value = e.target.value as string;
        const responseCoordinates = await api.get(`/api/getCoordinates/${station_id}`);
        setLatitude(responseCoordinates.data[0].latitude)
        setLongitude(responseCoordinates.data[0].longitude)
        setLocation(responseCoordinates.data[0].location)
        setSelectedStationId(value);
    };

    function validaItem() {
        if (items.length === 0) {
            toast.error("Selecione pelo menos um item do equipamento selecionado");
            return;
        }

        const equipmentItemList: string[] = [];
        equipmentItemList.push(items);
        setItemList((prevItems: any) => [...prevItems, ...equipmentItemList]);
    }

    function removeItem(itemToRemove: string) {
        setItemList((prevItems) => prevItems.filter((item) => item !== itemToRemove));
    }

   // Função para manipular a seleção do arquivo
   const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Obtendo o arquivo selecionado
    if (file) {
        setSelectedFile(file); // Armazenando o arquivo no estado
        try {
            const base64String = await convertToBase64(file); // Convertendo o arquivo para base64
            setPdf(base64String); // Armazenando a string base64 no estado
        } catch (error) {
            console.error('Erro ao converter o arquivo para base64:', error);
        }
    } else {
        setPdf("");
    }
};

    const convertToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
        });
    };


    return (
        <>
            <Box sx={{ display: "flex", flexDirection: "column", width: '100%', borderWidth: 10, borderColor: '#efefef' }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <TextField
                        sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
                        label={`${inputName[0].charAt(0).toUpperCase()}${inputName[0].slice(1)}`}
                        size='small'
                        value={equipmentName}
                        onChange={(e) => setEquipmentName(e.target.value)}
                    />
                    <TextField
                        sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
                        label={`${inputName[1].charAt(0).toUpperCase()}${inputName[1].slice(1)}`}
                        size='small'
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                    />
                    <TextField
                        select
                        sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
                        label={`${inputName[2].charAt(0).toUpperCase()}${inputName[2].slice(1)}`}
                        value={selectedTypeId}
                        onChange={(e) => setSelectedTypeId(e.target.value)}
                        size='small'
                        style={{ marginBottom: '20px' }}
                    >
                        {equipmentType.map((type) => (
                            <MenuItem key={type.id} value={type.id}>
                                {type.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
                        label={`${inputName[3].charAt(0).toUpperCase()}${inputName[3].slice(1)}`}
                        value={selectedStationId}
                        onChange={(e) => handleStationChange(e, e.target.value)}
                        size='small'
                        style={{ marginBottom: '20px' }}
                    >
                        {stations.map((station) => (
                            <MenuItem key={station.id} value={station.id}>
                                {station.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
                        label={`${inputName[4].charAt(0).toUpperCase()}${inputName[4].slice(1)}`}
                        size='small'
                        value={latitude}
                        onChange={(e) => setLatitude(e.target.value)}
                    />
                    <TextField
                        sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
                        label={`${inputName[5].charAt(0).toUpperCase()}${inputName[5].slice(1)}`}
                        size='small'
                        value={longitude}
                        onChange={(e) => setLongitude(e.target.value)}
                    />
                    <TextField
                        sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
                        label={`${inputName[6].charAt(0).toUpperCase()}${inputName[6].slice(1)}`}
                        size='small'
                        value={observation}
                        onChange={(e) => setObservation(e.target.value)}
                    />
                    <TextField
                        sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
                        label={`${inputName[7].charAt(0).toUpperCase()}${inputName[7].slice(1)}`}
                        size='small'
                        value={curiosity}
                        onChange={(e) => setCuriosity(e.target.value)}
                    />

                    <Box sx={{ flexDirection: 'row' }}>
                        <TextField
                            sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
                            label={`${inputName[8].charAt(0).toUpperCase()}${inputName[8].slice(1)}`}
                            size='small'
                            value={items}
                            onChange={(e) => setItems(e.target.value)}
                        />
                        <Button
                            size='small'
                            variant='contained'
                            sx={{
                                backgroundColor: '#111c5d',
                                width: '5%',
                                height: '66%',
                                marginLeft: 5,
                                fontSize: 10,
                                '&:hover': {
                                    backgroundColor: '#122079'
                                },
                            }}
                            onClick={validaItem}
                        >
                            Adicionar
                        </Button>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '600px' }}>
                        <ErrorIcon sx={{ color: '#A0A0A0', fontSize: '17px' }} />
                        <span style={{ display: 'flex', alignItems: 'center', marginLeft: '5px', flexDirection: 'column' }}>
                            <h5 style={{ fontSize: '13px', color: '#A0A0A0', margin: 0 }}>Itens que serão checados neste equipamento</h5>
                        </span>
                    </Box>
        
                        <Box style={{ display: "flex", flexWrap: "wrap", width: '50%' }}>
                            {itemList && itemList.map((item, index) => (
                                <Button
                                    key={index}
                                    style={{ flexDirection: "column", backgroundColor: "#111c5d" }}
                                    sx={{ margin: '5px 5px 0 0 ' }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => removeItem(item)}
                                >
                                    {item}
                                </Button>
                            ))}
                        </Box>
                        <br></br>
                        <Box style={{display: "flex", justifyContent: "space-between", width: '50%' }}>
                                <input
                                        type="file"
                                        accept=".pdf"
                                        style={{ display: 'none' }}
                                        onChange={handleFileUpload}
                                        id="file-input"
                                        />
                                <label htmlFor="file-input">
                                        <Button 
                                        variant="contained"
                                        component="span"
                                        sx={{ marginBottom: '20px' }}
                                        >
                                        Importar PDF
                                        </Button>
                                </label>
                        </Box>
                        <label htmlFor="file-input">
                                        {selectedFile && (
                                            <p style={{ fontSize: '15px' }}> Arquivo selecionado: {selectedFile.name}</p>
                                        )}
                                </label>

                    <Box sx={{ display: "flex", justifyContent: 'flex-end', width: '100%', marginY: '20px' }}>
                        <button
                            onClick={Equipment}
                            type="button"
                            className="btn btn-primary"
                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#122079'}
                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#111c5d'}
                            style={{ backgroundColor: '#111c5d', width: 100, height: 40, borderRadius: 5, bottom: 10, right: 10, padding: 0, alignSelf: 'flex-end', fontSize: 20, border: 'none' }}
                        >
                            <p style={{ fontSize: 16, margin: 0 }}>SALVAR</p>
                        </button>
                        <button
                            onClick={() => navigate(`/details/atividades`)}
                            type="button"
                            className="btn btn-primary"
                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#122079'}
                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#111c5d'}
                            style={{ backgroundColor: '#111c5d', width: 100, height: 40, borderRadius: 5, bottom: 10, right: 10, padding: 0, alignSelf: 'flex-end', fontSize: 20, marginLeft: 10, border: 'none' }}
                        >
                            <p style={{ fontSize: 16, margin: 0 }}>PRÓXIMO</p>
                        </button>
                    </Box>
                </Box>
            </Box >
        </>
    );
}
function setSelectedFile(arg0: File | null) {
    throw new Error("Function not implemented.");
}

