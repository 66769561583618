import { useState, useEffect } from "react";
import { Box, MenuItem } from "@mui/material";
import TextField from '@mui/material/TextField';
import { ToastContainer, toast } from "react-toastify";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";

interface CheckProps {
  itemsCheck: string[];
  setItemsCheck: React.Dispatch<React.SetStateAction<string[]>>;
}

export const Activity: React.FC<CheckProps> = ({ itemsCheck, setItemsCheck }) => {
  const [equipments, setEquipments] = useState<{ id: string, name: string }[]>([]);
  const [activityType, setActivityType] = useState<{ id: string, name: string }[]>([]);
  const [resetInputs, setResetInputs] = useState(false);
  const [type, setType] = useState<string>('');
  const [selectedEquipmentId, setSelectedEquipmentId] = useState<string>('');
  const [selectedActivityTypeId, setSelectedActivityTypeId] = useState<string>('');
  const [activityName, setActivityName] = useState<string>('');
  const name = "Nome da Atividade";
  const typeName = "Tipo de Atividade";
  const equipment = "Equipamentos"
  const types: string[] = ['Verificação', 'Validação', 'Manutenção'];
  const navigate = useNavigate();

  async function Activity() {
    try {
      const equipId = selectedEquipmentId !== '' ? selectedEquipmentId : null;
      const response = await api.post("/api/addActivities", {
        name: activityName,
        type: selectedActivityTypeId,
        equipId
      });
      if (response.status === 200 || response.status === 201) {
        setActivityName("")
        setType("")
        setSelectedEquipmentId("")
        setSelectedActivityTypeId("")

        toast.success("Cadastro de atividade realizado com sucesso!")
      } else {
        toast.error("Erro ao cadastrar uma atividade, por favor tente novamente!")
      }




    } catch (error: any) {
      console.error('Erro forte', error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseEquipments = await api.get<{ id: string, name: string }[]>("/api/getAllEquipments");

        const responseActivityType = await api.get<{ id: string, name: string }[]>("/api/getAllActivityTypes")


        setActivityType(responseActivityType.data)

        setEquipments(responseEquipments.data);
      } catch (error: any) {
        console.error('Error fetching equipment data:', error);
      }
    };

    fetchData();
  }, [resetInputs]);

  useEffect(() => {
    if (resetInputs) {
      setEquipments([]);
      setType('');
    }
  }, [resetInputs]);


  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", width: '100%', borderWidth: 10, borderColor: '#efefef' }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <TextField
            sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
            label={`${name.charAt(0).toUpperCase()}${name.slice(1)}`}
            size='small'
            value={activityName}
            onChange={(e) => setActivityName(e.target.value)}
          />
          <TextField
            select
            sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
            label={`${typeName.charAt(0).toUpperCase()}${typeName.slice(1)}`}
            size='small'
            value={selectedActivityTypeId}
            onChange={(e) => setSelectedActivityTypeId(e.target.value)}
          >
            {activityType.map((activityType) => (
              <MenuItem key={activityType.id} value={activityType.id}>
                {activityType.name}
              </MenuItem>
            ))}
          </TextField>
          {String(selectedActivityTypeId) === '2' && (
            <TextField
              select
              sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
              label={`${equipment.charAt(0).toUpperCase()}${equipment.slice(1)}`}
              size='small'
              value={selectedEquipmentId}
              onChange={(e) => setSelectedEquipmentId(e.target.value)}
            >
              {equipments.map((equipment) => (
                <MenuItem key={equipment.id} value={equipment.id}>
                  {equipment.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          <Box sx={{ display: "flex", justifyContent: 'flex-end', width: '100%', marginY: '20px' }}>
            <button
              onClick={Activity}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#122079'}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#111c5d'}
              type="button"
              className="btn btn-primary"
              style={{ backgroundColor: '#111c5d', width: 100, height: 40, borderRadius: 5, bottom: 10, right: 10, padding: 0, alignSelf: 'flex-end', fontSize: 20, border: 'none' }}
            >
              <p style={{ fontSize: 16, margin: 0 }}>SALVAR</p>
            </button>
            <button
              onClick={() => navigate(`/details/rotinas`)}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#122079'}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#111c5d'}
              type="button"
              className="btn btn-primary"
              style={{ backgroundColor: '#111c5d', width: 100, height: 40, borderRadius: 5, bottom: 10, right: 10, padding: 0, alignSelf: 'flex-end', fontSize: 20, marginLeft: 10, border: 'none' }}
            >
              <p style={{ fontSize: 16, margin: 0 }}>PRÓXIMO</p>
            </button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
