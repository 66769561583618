import React, { useEffect, useRef, useState } from 'react';
import QRCode from 'qrcode.react';
import { useLocation } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

interface QRCodePageProps { }

interface Equipment {
  Id: number;
  Nome: string;
}

const QRCodePage: React.FC<QRCodePageProps> = () => {
  const qrCodeRef = useRef<any>(null);
  const location = useLocation();
  const [equipment, setEquipment]: any = useState({});


  useEffect(() => {
    const item: Equipment = location.state ? location.state.item : { Id: 0, Nome: '', };

    const equipmentObj = { id: item.Id, name: item.Nome }
    setEquipment(equipmentObj)

    if (qrCodeRef.current) {
      qrCodeRef.current.makeCode(qrCodeContent);
    }
  }, [location.state]);
  const qrCodeContent = `http://35.209.143.50/equipmentQrCodeInfo/${equipment.id}`;
  return (
    <>
      <Row className="justify-content-center align-items-center" style={{ height: '100vh' }}>
      <Col md={2} className="text-center">
        <h1>{equipment?.name}</h1>
        <QRCode value={qrCodeContent} size={200} />
      </Col>
     </Row>
    </>
  );
};

export default QRCodePage;
