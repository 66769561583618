import React, { useState, useEffect } from "react";
import { Box, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";

interface CheckProps {
    itemsCheck: string[];
    setItemsCheck: React.Dispatch<React.SetStateAction<string[]>>;
}

export const Technical: React.FC<CheckProps> = ({
    itemsCheck,
    setItemsCheck,
}) => {
    const [technicalName, setTechnicalName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [selectedPrimaryStationId, setSelectedPrimaryStationId] = useState<string>('');
    const [stations, setStations] = useState<any[]>([]);
    const navigate = useNavigate();

    async function handleSaveTechnical() {
        try {
            const response = await api.post("/api/addTechnical", {
                name: technicalName,
                email: email,
                password: password,
                stationId: selectedPrimaryStationId
            });

            if (response.status === 200) {
                setTechnicalName("");
                setEmail("");
                setPassword("");
                setSelectedPrimaryStationId("");

                toast.success("Usuário cadastrado com sucesso!");
            } else {
                toast.error("Erro ao cadastrar um usuário, tente novamente!");
            }
        } catch (error) {
            console.error('Erro ao cadastrar técnico', error);
            toast.error("Erro ao cadastrar um usuário, tente novamente!");
        }
    }

    useEffect(() => {
        const fetchStations = async () => {
            try {
                const response = await api.get<{ id: string, name: string }[]>(`/api/getAllStation`);
                setStations(response.data);
            } catch (error) {
                console.error('Erro ao buscar estações', error);
            }
        };

        fetchStations();
    }, []);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: '100%',
                borderWidth: 10,
                borderColor: '#efefef',
            }}
        >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <TextField
                    sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
                    label="Nome"
                    size='small'
                    value={technicalName}
                    onChange={(e) => setTechnicalName(e.target.value)}
                />
                <TextField
                    sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
                    label="Código do Usuário"
                    size='small'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
                    label="Senha"
                    type="password"
                    size='small'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                    select
                    sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
                    label="Estação Principal"
                    value={selectedPrimaryStationId}
                    onChange={(e) => setSelectedPrimaryStationId(e.target.value)}
                    size='small'
                >
                    {stations.map((station) => (
                        <MenuItem key={station.id} value={station.id}>
                            {station.name}
                        </MenuItem>
                    ))}
                </TextField>
                <Box sx={{ display: "flex", justifyContent: 'flex-end', width: '100%', marginY: '20px' }}>
                    <button
                        onClick={handleSaveTechnical}
                        type="button"
                        className="btn btn-primary"
                        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#122079'}
                        onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#111c5d'}
                        style={{ backgroundColor: '#111c5d', width: 100, height: 40, borderRadius: 5, padding: 0, alignSelf: 'flex-end', fontSize: 20, border: 'none' }}
                    >
                        <p style={{ fontSize: 16, margin: 0 }}>SALVAR</p>
                    </button>
                    <button
                        onClick={() => navigate(`/home`)}
                        type="button"
                        className="btn btn-primary"
                        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#122079'}
                        onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#111c5d'}
                        style={{ backgroundColor: '#111c5d', width: 100, height: 40, borderRadius: 5, padding: 0, alignSelf: 'flex-end', fontSize: 20, marginLeft: 10, border: 'none' }}
                    >
                        <p style={{ fontSize: 16, margin: 0 }}>PRÓXIMO</p>
                    </button>
                </Box>
            </Box>
        </Box>
    );
};
