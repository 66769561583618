import {useState} from "react";
import {Box, Button} from "@mui/material";
import TextField from '@mui/material/TextField';
import { toast } from "react-toastify";

interface CheckProps {
    itemsCheck: string[];
    setItemsCheck: React.Dispatch<React.SetStateAction<string[]>>;
}

export const CheckList: React.FC<CheckProps> = ({itemsCheck, setItemsCheck}) => {
    const [itemCheck, setItemCheck] = useState('');


    const handleItemCheck = (e: any) => {
        setItemCheck(e)
    }

    function removeItem(itemToRemove:string) {
        setItemsCheck((prevItems) => prevItems.filter((item) => item !== itemToRemove));


    }

    function validaItem() {
        let duplicado = []
        duplicado = itemsCheck.filter((item) => item === itemCheck)
        if (duplicado.length > 0) {
            toast.warn("Item ja está na lista")
            return
        }else{
            itemCheck.length &&
            setItemsCheck([...itemsCheck, itemCheck]);
            setItemCheck("")
        }
}

    return(

            <Box sx={{display:"flex", flexDirection:"column", width:'100%'}}>
                <Box sx={{display:"flex", justifyContent:'flex-start'}}>

                    <TextField
                        sx={{minWidth:"310px", marginRight:'5px'}}
                        id='Checklist'
                        key="Checklist"
                        label="CheckList"
                        value={itemCheck || ""}
                        size='small'
                        onChange={(e) => handleItemCheck(e.target.value)}
                    />
                    <button onClick={()=> validaItem() }
                            type="button"
                            className="btn btn-primary">
                    Adicionar</button>
                </Box>


                <Box style={{display:"flex",  flexWrap:"wrap", width:'50%'}}>
                    {itemsCheck && itemsCheck.map((item, index) => (
                                <Button
                                    key={index}
                                    sx={{margin:'5px 5px 0 0 '}}
                                    size='small'
                                    variant='contained'
                                    onClick={()=> removeItem(item)} style={{}}>{item}</Button>

                        )
                    )}
                </Box>
            </Box>




    )
}
