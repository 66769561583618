

import { useState, useEffect } from "react";
import { Box, MenuItem, TextField } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import api from "../../services/api";
import Select from 'react-select';
import { customStyles } from "./styles";
import { Body } from "../../components/Body";

interface SelectedOption {
    label: string;
    value: number;
}

export function AssignUsers() {
    const [resetInputs, setResetInputs] = useState(false);
    const [isStationSelected, setIsStationSelected] = useState(false);
    const [routines, setRoutines] = useState<any[]>([]);
    const [users, setUsers] = useState<any[]>([]);
    const [allRoutines, setAllRoutines] = useState<any[]>([]);
    const [stations, setStations] = useState<any[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
    const [selectedUserOptions, setSelectedUserOptions] = useState<any[]>([]);
    const [deletedRoutinesArray, setDeletedRoutinesArray] = useState<any[]>([]);
    const [selectKey, setSelectKey] = useState(0);

    const [selectedStationId, setSelectedStationId] = useState<string>('');

    async function saveAssignUsers() {
        try {
            const technicalStation = selectedUserOptions.map((item: SelectedOption) => ({
                stationId: selectedStationId,
                technicalId: item.value,
            }));

            const technicalRoutine = selectedOptions.flatMap((item: SelectedOption) =>
                selectedUserOptions.map((user: SelectedOption) => ({
                    routineId: item.value,
                    technicalId: user.value,
                }))
            );


            const responseTechnicalStation = await api.post("/api/assignTechnicalStation", {
                technicalStation: technicalStation
            });

            const responseTechnicalORutine = await api.post("/api/assignTechnicalRoutine", {
                technicalRoutine: technicalRoutine
            });

            setSelectedStationId('');
            setAllRoutines([]);
            setSelectedOptions([]);
            setSelectedUserOptions([]);
            setSelectKey((prevKey) => prevKey + 1);
            setIsStationSelected(true);
            setResetInputs((prevResetInputs) => !prevResetInputs);

            toast.success("Usuários atribuídos com sucesso!");
        } catch (error: any) {
            console.error('Erro forte', error);
        }
    }

    const handleStationChange = async (station_id: string) => {
        try {
            setSelectedStationId(station_id);
            setIsStationSelected(false);

            const responseUsers = await api.get<{ id: string; name: string }[]>(`/api/getAllTechnical`);
            const usersData = responseUsers.data;

            const responseStationRoutines = await api.get<{ routine_id: string; name: string }[]>(`/api/getAllStationRoutine/${station_id}`);
            const stationRoutines = responseStationRoutines.data;

            const defaultValues = stationRoutines.map((linkedRoutine) => ({
                value: linkedRoutine.routine_id,
                label: linkedRoutine.name,
            }));

            const userValues = usersData.map((user) => ({
                value: user.id,
                label: user.name,
            }));

            setRoutines(defaultValues);
            setUsers(userValues)
            setIsStationSelected(true);

        } catch (error: any) {
            console.error("Error handling station change:", error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseStation = await api.get<{ id: string, name: string }[]>(`/api/getAllStation`);
                setStations(responseStation.data);
            } catch (error: any) {
                console.error('Error fetching equipment data:', error);
            }
        };

        fetchData();
    }, [resetInputs]);

    const handleSelectChange = (selectedOptions: any) => {
        setSelectedOptions(selectedOptions);
    };

    const handleSelectUserChange = (selectedOptions: any) => {
        setSelectedUserOptions(selectedOptions);
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: '100%',
                    borderWidth: 10,
                    borderColor: '#efefef',
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 10
                    }}
                >
                    <Box
                        sx={{
                            borderStyle: 'solid',
                            borderWidth: 1,
                            borderColor: '#111c5d',
                            padding: 2,
                            borderRadius: 3,
                            width: '500px',
                            flexDirection: 'column',
                            position: 'relative',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <span
                            style={{
                                position: 'absolute',
                                top: -14,
                                left: 10,
                                backgroundColor: '#FFF',
                                padding: '0 5px',
                                color: '#111c5d',
                                zIndex: 1
                            }}
                        >
                            Atribuir Usuarios
                        </span>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                            <div style={{ marginBottom: '20px' }}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <Box sx={{ flexDirection: 'column' }}>
                                        <TextField
                                            select
                                            sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
                                            label="Estação"
                                            value={selectedStationId}
                                            onChange={(e) => handleStationChange(e.target.value)}
                                            size='small'
                                        >
                                            {stations.map((station) => (
                                                <MenuItem key={station.id} value={station.id}>
                                                    {station.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        {isStationSelected && users && (
                                            <div style={{ marginTop: '30px' }}>
                                                <p style={{ fontSize: '13px', color: '#A0A0A0' }}>Atribuição de Usuários a Rotinas Específicas</p>
                                                <Select
                                                    key={selectedStationId}
                                                    isMulti
                                                    options={users}
                                                    styles={customStyles}
                                                    onChange={(selectedOptions) => handleSelectUserChange(selectedOptions)}
                                                    placeholder="Usuários"
                                                />
                                            </div>

                                        )}
                                        {isStationSelected && routines && (
                                            <Select
                                                key={selectedStationId + "routines"}
                                                isMulti
                                                options={routines}
                                                styles={customStyles}
                                                onChange={(selectedOptions) => handleSelectChange(selectedOptions)}
                                                placeholder="Rotinas"
                                            />
                                        )}
                                    </Box>
                                </div>
                            </div>
                        </div>
                        <button
                            onClick={saveAssignUsers}
                            type="button"
                            className="btn btn-primary"
                            style={{
                                backgroundColor: '#111c5d',
                                width: 100,
                                height: 30,
                            }}
                        >
                            <p style={{ fontSize: 12, margin: 0 }}>SALVAR</p>
                        </button>

                    </Box>
                </Box>
            </Box>
        </>
    );
};