import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../services/api";
import "../../index.css";
import './styles.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState("");

    async function login() {
      try {
        const response = await api.post(`/api/login`, { email, password });
        const { token } = response.data;

        if (token && token.token) {
          const expirationDate = new Date(token.expires_at);
          const currentDate = new Date();
          if (expirationDate > currentDate) {
            localStorage.setItem("token", token.token);
            navigate("/details/agendamentos");
          } else {
            setError("O token de autenticação expirou. Faça login novamente.");
          }
        } else {
          setError("Credenciais inválidas. Verifique seu e-mail e senha.");
        }
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          setError("E-mail ou senha incorreta.");
        } else {
          console.log(error);
          setError("Ocorreu um erro ao fazer login. Por favor, tente novamente mais tarde.");
        }
      }
    }


    return (
        <div className="login">
            <div className="guide-left">
                <div className="tag">
                <img className="logo-branca" src={process.env.PUBLIC_URL + '/taskviewLogo.svg'} alt="Logo" />
                    <div className="seguran-a-do">MANAGE YOUR OBJECTIVES, ACT WITH PRECISION</div>
                </div>
            </div>
            <div className="guide-right">
                <div className="content-login">
                    <form className="inside-content" onSubmit={(e) => { e.preventDefault(); login(); }}>
                        <label htmlFor="access" className="access-label">Login</label>

                        <div className="form-group-auth">
                            <label htmlFor="email">DIGITE SEU E-MAIL</label>
                            <input type="text" placeholder="Digite seu e-mail" value={email}
                                onChange={(e) => setEmail(e.target.value)} />
                        </div>

                        <div className="form-group-auth">
                            <label htmlFor="pass">DIGITE SUA SENHA</label>
                            <div className="pass-auth">
                                <input type={showPassword ? "text" : "password"} placeholder="Digite sua senha" value={password}
                                    onChange={(e) => setPassword(e.target.value)} />
                                <button
                                    type="button"
                                    className="show-hide-button"
                                    onClick={() => setShowPassword(!showPassword)}>{showPassword ? (<VisibilityIcon style={{color: '#111c5d'}}/>) : (<VisibilityOffIcon style={{color: '#111c5d'}}/>)}</button>
                            </div>
                        </div>

                        <div className="pass-forget">
                            <a href="#" style={{color: '#111c5d'}}>Esqueci minha senha</a>
                        </div>

                        <div className="btn-content">
                            <button className="btn-access" onClick={login}>Entrar</button>
                        </div>
                        {error && (
                            <div className="my-3 text-danger">
                                <p className="text-center mb-3">{error}</p>
                            </div>
                        )}
                    </form>
                    {/* <div className="register">
                        <span>Ainda não tem uma conta?</span> <a href="/new-register" style={{color: '#111c5d'}}>Cadastre-se</a>
                    </div> */}
                </div>
            </div>
        </div>
    );
}
