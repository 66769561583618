import "bootstrap/dist/css/bootstrap.css";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Box, Card, TableHead, CircularProgress } from "@mui/material";
import api from "../../services/api";
import { Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import React from "react";

interface ModalEquipamentProps {
  data: {
    schedulingId: string;
    equipamentId: string;
  };
}

export const ModalEquipament: React.FC<ModalEquipamentProps> = ({ data }) => {
  const { schedulingId, equipamentId } = data;

  const [atividadesPorData, setAtividadesPorData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        let responseHistory;

        // Tentar buscar com schedulingId primeiro
        if (schedulingId) {
          responseHistory = await api.get(`/api/searchCheckedItems/${schedulingId}`);
          if (responseHistory.data.length > 0) {
            setAtividadesPorData(responseHistory.data);  // Se houver dados, usar
          } else if (equipamentId) {
            // Se não houver dados com schedulingId, tentar com equipamentId
            responseHistory = await api.get(`/api/searchCheckedEquipamentsItems/${equipamentId}`);
            // Modifica o campo checked_item para 3
            const modifiedData = responseHistory.data.map((item: any) => ({
              ...item,
              checked_item: 3, // Força o checked_item a ser 3
            }));
            setAtividadesPorData(modifiedData);
          }
        } else if (equipamentId) {
          // Se não tiver schedulingId, buscar diretamente com equipamentId
          responseHistory = await api.get(`/api/searchCheckedEquipamentsItems/${equipamentId}`);
          // Modifica o campo checked_item para 3
          const modifiedData = responseHistory.data.map((item: any) => ({
            ...item,
            checked_item: 3, // Força o checked_item a ser 3
          }));
          setAtividadesPorData(modifiedData);
        } else {
          console.error("Neither schedulingId nor equipamentId is provided");
          return; // Sair se nenhum ID foi fornecido
        }
      } catch (error) {
        console.error('Erro ao obter atividades:', error);
        setError('Erro ao obter atividades. Por favor, tente novamente mais tarde.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [schedulingId, equipamentId]);

  const renderAtividadesPorData = () => {
    if (atividadesPorData.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={3} align="center">Nenhuma atividade disponível.</TableCell>
        </TableRow>
      );
    }

    return atividadesPorData.map((activity, index) => (
      <TableRow key={index}>
        <TableCell>{activity.equipment_name}</TableCell>
        <TableCell>{activity.item_name}</TableCell>
        <TableCell>
          {activity.checked_item === 2 ? (
            <CancelIcon sx={{ color: 'red', marginLeft: 2 }} />
          ) : activity.checked_item === 1 ? (
            <CheckCircleIcon sx={{ color: 'green', marginLeft: 2 }} />
          ) : activity.checked_item === 4 || null ? (
            <ReportProblemIcon sx={{ color: 'orange', marginLeft: 2 }} />
          ) : activity.checked_item == 3 && (
            <SyncProblemIcon sx={{ color: '#FF8000', marginLeft: 2 }} />
          )}
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <Box>
      <Card sx={{ margin: '10px 0', width: '100%', marginTop: '20px', boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)' }}>
        <TableContainer>
          <Table size="large">
            <TableHead>
              <TableRow>
                <TableCell>Equipamentos</TableCell>
                <TableCell>Itens</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : error ? (
                <TableRow>
                  <TableCell colSpan={3} align="center">{error}</TableCell>
                </TableRow>
              ) : (
                renderAtividadesPorData()
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Box>
  );
};
