import React, { useEffect, useState } from "react";
import { MenuItem, TextField, CircularProgress, Typography } from "@mui/material";
import api from "../../../services/api";

interface EditFieldProps {
    label: string;
    itemData: { [key: string]: any }[];
    setData: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
    url: string;
}

export const EditField: React.FC<EditFieldProps> = ({ label, itemData, setData, url }) => {
    const [response, setResponse] = useState<{ id: string; name: string }[]>([]);
    const [defaultValue, setDefaultValue] = useState<string | number | "">("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const handleChange = (label: string, value: string) => {
        setData((prevData) => ({
            ...prevData,
            [label]: value,
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await api.get<{ id: string; name: string }[]>(url);
                setResponse(response.data);

                const currentDataValue = itemData[0]?.[label];

                if (response.data.some(item => item.id === currentDataValue)) {
                    setDefaultValue(currentDataValue);
                } else {
                    setDefaultValue("");
                }
            } catch (error) {
                setError("Erro ao buscar os dados.");
                console.error("Erro ao buscar os dados:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [itemData, label, url]);

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <>
            {itemData.map((dataItem, index) => (
                <TextField
                    key={index}
                    select
                    sx={{ width: '100%', marginBottom: '20px' }}
                    label={label}
                    size='small'
                    defaultValue={dataItem[label] || defaultValue}
                    onChange={(e) => handleChange(label, e.target.value)}
                >
                    {response.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                            {item.name}
                        </MenuItem>
                    ))}
                </TextField>
            ))}
        </>
    );
};
