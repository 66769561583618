import { Routes, Route } from 'react-router-dom';
import { Details } from './pages/Details';
import Login from './pages/Login';
import { ToastContainer } from 'react-toastify';
import QRCodePage from './pages/QrCode';
import { EquipmentInfos } from './pages/EquipmentInfos';
import { EquipmentGeneralInfo } from './pages/EquipmentGeneralInfo';
import NewRegister from './pages/NewRegister';
import { Home } from './pages/Home';
import { HomeDetails } from './pages/HomeDetails';
import { AssignRoutine } from './pages/AssignRoutine';
import { AssignUsers } from './pages/AssignTechnicals';
import { Mapas } from './pages/Mapas';
import { AssignActivities } from './pages/AssignActivities';
import { Historico } from './pages/Historico';


export default function App() {
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="new-register" element={<NewRegister />} />
        <Route path="home" element={<Home />} />
        <Route path="homeDetails/:routineId/:stationId/:technicalId" element={<HomeDetails data={[]} />} />
        <Route path="details/:menuRota" element={<Details />} />
        <Route path="assignRoutine" element={<AssignRoutine />} />
        <Route path="mapas" element={<Mapas/>} />
        <Route path="historico" element={<Historico/>} />
        <Route path="assignUsers" element={<AssignUsers />} />
        <Route path="qrcode/:equipmentId" element={<QRCodePage />} />
        <Route path="equipmentQrCodeInfo/:equipmentId" element={<EquipmentInfos />} />
        <Route path="assignActivities" element={<AssignActivities />} />
      </Routes>
    </>
  );
}
