import React, { useState, useCallback, useEffect } from "react";
import { Box, Button, TextField } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";

interface CheckProps {
  itemsCheck: string[];
  setItemsCheck: React.Dispatch<React.SetStateAction<string[]>>;
}

export const Routine: React.FC<CheckProps> = ({ itemsCheck, setItemsCheck }) => {
  const [routineName, setRoutineName] = useState<string>('');
  const navigate = useNavigate();

  const saveRoutine = async () => {
    try {
      const responseRoutine = await api.post("/api/addRoutine", { name: routineName });

      if (responseRoutine.status === 200) {
        setRoutineName("");
        toast.success("Cadastro da rotina realizada com sucesso");
      } else {
        toast.error("Erro ao cadastrar a rotina, por favor tente novamente!");
      }
    } catch (error: any) {
      console.error('Erro ao salvar a rotina', error);
    }
  };

  const removeItem = (itemToRemove: string) => {
    setItemsCheck((prevItems) => prevItems.filter((item) => item !== itemToRemove));
  };

  return (
    <>
      <div>
        <Box sx={{ display: "flex", flexDirection: "column", width: '100%' }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <TextField
              sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
              label="Nome"
              size='small'
              value={routineName}
              onChange={(e) => setRoutineName(e.target.value)}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: 'flex-end', width: '100%', marginY: '20px' }}>
            <button
              onClick={saveRoutine}
              type="button"
              className="btn btn-primary"
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#122079'}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#111c5d'}
              style={{ backgroundColor: '#111c5d', width: 100, height: 40, borderRadius: 5, bottom: 10, right: 10, padding: 0, alignSelf: 'flex-end', fontSize: 20, border: 'none' }}
            >
              <p style={{ fontSize: 16, margin: 0 }}>SALVAR</p>
            </button>
            <button
              onClick={() => navigate(`/details/atribuir%20rotinas`)}
              type="button"
              className="btn btn-primary"
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#122079'}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#111c5d'}
              style={{ backgroundColor: '#111c5d', width: 100, height: 40, borderRadius: 5, bottom: 10, right: 10, padding: 0, alignSelf: 'flex-end', fontSize: 20, marginLeft: 10, border: 'none' }}
            >
              <p style={{ fontSize: 16, margin: 0 }}>PRÓXIMO</p>
            </button>
          </Box>
        </Box>
      </div>
      <ToastContainer />
    </>
  );
};
