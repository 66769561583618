import Select from 'react-select';

export const customStyles = {
    control: (provided: any) => ({
        ...provided,
        display: 'flex',
        width: '300px',
        maxWidth: '300px',
        overflowX: 'auto'
    }),
    menu: (provided: any) => ({
        ...provided,
        width: '400px',
        maxWidth: '400px',
    }),
   
};


