import "bootstrap/dist/css/bootstrap.css";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box } from "@mui/material";
import { Body } from "../../components/Body";
import Select from 'react-select';
import { customStyles } from "./styles";
import api from "../../services/api";
import { Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Paper, Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';

export function Home() {
    const [stations, setStations] = useState<any[]>([]);
    const [technical, setTechnical] = useState<any[]>([]);
    const [data, setData] = useState<any[]>([]);
    const [dataRoutine, setDataRoutine] = useState<any[]>([]);
    const [routineType, setRoutineType] = useState<any[]>([]);
    const [selectedStations, setSelectedStations] = useState<any[]>([]);
    const [selectedTechnical, setSelectedTechnical] = useState<any[]>([]);
    const [selectedDataRoutine, setSelectedDataRoutine] = useState<any[]>([]);
    const [selectedRoutineType, setSelectedRoutineType] = useState<any[]>([]);
    const [activities, setActivities] = useState<any[]>([]);
    const [selectedActivities, setSelectedActivities] = useState<any[]>([]);
    const [isUpdated, setIsUpdated] = useState(false); // Estado para controlar a atualização
    const navigate = useNavigate();

    const inputName: string[] = ["Estação","Rotina","Atividade","Tipo Atividade", "Técnico", "Dia/Semana", "Data/Realização", "Status"];

    const thead: string[] = ["Estação","Rotina","Atividade","Tipo Atividade", "Técnico", "Dia/Semana", "Data/Realização", "Status"];

    const formatDateTime = (dateTimeString: string | number | Date) => {
        const options: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        };

        const dateTime = new Date(dateTimeString);
        return dateTime.toLocaleString('pt-BR', options);
    };

    const handleVisualizarClick = (routine_id: number, station_id: number, technical_id: number) => {
        const selectedData = data.find((item) => item.routine_id === routine_id && item.station_id === station_id && item.technical_id === technical_id);
        navigate(`/homeDetails/${selectedData.routine_id}/${selectedData.station_id}/${selectedData.technical_id}`, { state: [selectedData] });
    }

    const handleFilter = async () => {
        try {
            const response = await api.post('/api/getAllDataFromRoutine', {
                stations: selectedStations,
                technicals: selectedTechnical,
                activities: selectedActivities,
              });
              
              const filteredData = response.data;
              const formattedData = filteredData.map((item: {
                station_name: any;
                routine_name: any;
                technical_name: any;
                activity_name: any;
                activity_type: any;
                status: any;
                date: any;
                weekday: any;
              }) => ({
                Estação: item.station_name,
                Rotina: item.routine_name,
                Atividade: item.activity_name,
                "Tipo Atividade": item.activity_type,
                Técnico: item.technical_name,
                "Dia/Semana": item.weekday,
                "Data/Realização": formatDateTime(item.date),
                Status: item.status,
                Ações: "Ações",
          }));
          console.log("🚀 ~ handleFilter ~ formattedData:", formattedData)
          setData(formattedData);
        } catch (error) {
          console.error('Error filtering data:', error);
        }
          
      };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseStation = await api.get<{ id: string, name: string }[]>(`/api/getAllStation`);
                const responseTechnical = await api.get<{ id: string, name: string }[]>(`/api/getAllTechnical`);
                const responseActivities = await api.get<{ id: string, name: string }[]>(`/api/getAllActivities`);
                const responseJson = await api.post('/api/getAllDataFromRoutine');
                const responseData = responseJson.data;
                const formattedData = responseData.map((item: {
                    [x: string]: any;
                }) => ({
                    Estação: item.station_name,
                    Rotina: item.routine_name,
                    Atividade: item.activity_name,
                    "Tipo Atividade": item.activity_type,
                    Técnico: item.technical_name,
                    "Dia/Semana": item.weekday,
                   "Data/Realização": formatDateTime(item.date),
                    Status: item.status,
                    Ações: "Ações", // Adicione lógica apropriada para ações, se necessário
                }));
                setData(formattedData);

                setTechnical(responseTechnical.data);
                setStations(responseStation.data);
                setSelectedActivities(responseActivities.data);
            } catch (error: any) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <Body>
            <Box sx={{ margin: '0 0 0 30px', display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
                <h2 className="fw-bold mb-2" style={{ margin: '20px 0 20px 0', padding: '0' }}>Agendamentos</h2>
                <Box sx={{ display: 'flex', gap: '10px', marginTop: 5, width: '100%' }}>
                    <Select
                        isMulti
                        styles={customStyles}
                        options={stations.map((station) => ({ value: station.id, label: station.name }))}
                        placeholder={inputName[0]}
                        onChange={(selectedOptions) => setSelectedStations(selectedOptions.map((option) => option.value))}
                    />
                    <Select
                        isMulti
                        styles={customStyles}
                        options={technical.map((technical) => ({ value: technical.id, label: technical.name }))}
                        placeholder={inputName[1]}
                        onChange={(selectedOptions) => setSelectedTechnical(selectedOptions.map((option) => option.value))}
                    />
                    <Button
                        sx={{
                            height: '38px',
                            backgroundColor: '#111c5d',
                            ':hover': {
                                backgroundColor: '#122079',
                            }
                        }}
                        variant="contained"
                        size="small"
                        type="button"
                        className="btn btn-primary"
                        onClick={() => handleFilter()}
                    >
                        Filtrar
                    </Button>
                </Box>
                <TableContainer component={Paper} sx={{ maxHeight: '50vh', marginTop: 5, marginBottom: 2, width: '98%' }}>
                    <Table size="medium" aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {thead.map((item, index) => (
                                    <TableCell key={index}>{item}</TableCell>
                                ))}
                                <TableCell>Ação</TableCell>
                                <TableCell>Mapa</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.length > 0 ? (
                                data.map((item: any, index: number) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        {thead.map((label, labelIndex) => (
                                            <TableCell key={`${index}-${labelIndex}`}>{item[label]}</TableCell>
                                        ))}
                                        <TableCell key={`action-${index}`}>
                                            <Button
                                                sx={{
                                                    height: '25px',
                                                    backgroundColor: '#111c5d',
                                                    ':hover': {
                                                        backgroundColor: '#122079',
                                                    }
                                                }}
                                                variant="contained"
                                                size="small"
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => handleVisualizarClick(item.routine_id, item.station_id, item.technical_id)}
                                            >
                                                Visualizar
                                            </Button>
                                            
                                        </TableCell>
                                        <TableCell key={`action-${index}`}>
                                            <Button
                                                sx={{
                                                    height: '25px',
                                                    backgroundColor: '#111c5d',
                                                    ':hover': {
                                                        backgroundColor: '#122079',
                                                    }
                                                }}
                                                variant="contained"
                                                size="small"
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => handleVisualizarClick(item.routine_id, item.station_id, item.technical_id)}
                                            >
                                                Visualizar
                                            </Button>
                                            
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={thead.length + 1} style={{ textAlign: 'center' }}>
                                        Para aparecer os dados, você deve realizar o fluxo de cadastro. Para isso, clique no botão "Iniciar Fluxo" e cadastre uma estação.
                                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    height: '38px',
                                                    backgroundColor: '#111c5d',
                                                    ':hover': {
                                                        backgroundColor: '#122079',
                                                    }
                                                }}
                                                onClick={() => navigate(`/details/estacao`)}
                                            >
                                                Iniciar Fluxo
                                            </Button>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Body>
    );
}
