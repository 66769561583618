import styled from "@emotion/styled";

export const customStylesTwo = {

    multiValue: (provided: any) => ({
        ...provided,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderWidth: 1.5,
        borderColor: '#111c5d',

    }),
    multiValueRemove: (provided: any) => ({
        ...provided,
        color: 'red',
        ':hover': {
            color: 'black',
        },
    }),
}

export const customStyles = {
    control: (provided: any) => ({
        ...provided,
        display: 'flex',
        width: '463px',
        maxWidth: '463px',
        overflowX: 'auto',
        minWidth: 200, 
            // marginBottom: '20px'
    }),
    menu: (provided: any) => ({
        ...provided,
        width: '463px',
        maxWidth: '463px',
        zIndex: 9999, 
    }),
    option: (provided: any, state: { isSelected: any; }) => ({
        ...provided,
        height: '30px',
        display: 'flex',
        alignItems: 'center',
    }),
    multiValue: (provided: any) => ({
        ...provided,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderWidth: 1.5,
        borderColor: '#111c5d',

    }),
    multiValueRemove: (provided: any) => ({
        ...provided,
        color: 'red',
        ':hover': {
            color: 'black',
        },
    }),
}
