
import { useState, useEffect } from "react";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import api from "../../services/api";
import Select from 'react-select';
import { customStyles } from "./styles";
import { useNavigate } from "react-router-dom";
import { Body } from "../../components/Body";
import { customStylesTwo } from "../AssignRoutine/styles";
import { weekDayNames } from "../../utils/enums/WeekDay";
import MapContainer from "../../components/Map";


interface SelectedOption {
  label: string;
  value: number;
}

interface Routine {
  id: string;
  name: string;
}

export function Mapas() {
    const [stations, setStations] = useState<any[]>([]);
        const [technical, setTechnical] = useState<any[]>([]);
        const [activities, setActivities] = useState<any[]>([]);
        const [routines, setRoutines] = useState<any[]>([]);
        const [selectedStations, setSelectedStations] = useState<string[]>([]);
        const [selectedTechnical, setSelectedTechnical] = useState<string[]>([]);
        const [selectedRoutines, setSelectedRoutines] = useState<string[]>([]);
        const [selectedWeekdays, setSelectedWeekdays] = useState<string[]>([]);
        const [positions, setPositions] = useState<any[]>([]);
        const [mapVisible, setMapVisible] = useState<boolean>(false);
    
        const inputName: string[] = ["Estação", "Usuário", "Rotina", "Dia da semana"];
    
        const handleFilter = async () => {
            try {
                const response = await api.post('/api/getAllDataFromRoutine', {
                    stations: selectedStations,
                    technicals: selectedTechnical,
                    routines: selectedRoutines
                });
            } catch (error) {
                console.error('Error filtering data:', error);
            }
        };
    
        useEffect(() => {
            const fetchData = async () => {
                try {
                    const responseStation = await api.get<{ id: string, name: string }[]>('/api/getAllStation');
                    const responseTechnical = await api.get<{ id: string, name: string }[]>('/api/getAllTechnical');
                    const responseActivities = await api.get<{ id: string, name: string }[]>('/api/getAllActivities');
                    const responseRoutines = await api.get<{ id: string, name: string }[]>('/api/getAllRoutine');
                    setStations(responseStation.data || []);
                    setTechnical(responseTechnical.data || []);
                    setActivities(responseActivities.data || []);
                    setRoutines(responseRoutines.data || []);
                } catch (error: any) {
                    console.error('Error fetching data:', error);
                }
            };
    
            fetchData();
        }, []);
    
        const handleWeekdayChange = (selectedOption: any) => {
            setSelectedWeekdays(selectedOption ? [selectedOption.value] : []);
        };
    
        const handleButtonMap = async () => {
            if (selectedWeekdays.length === 0 || selectedRoutines.length === 0 || selectedStations.length === 0 || selectedTechnical.length === 0) {
                console.error('Please select all required filters.');
                return;
            }
    
            setPositions([]);
            const weekday_id = selectedWeekdays[0];
            const routine_id = selectedRoutines[0];
            const station_id = selectedStations[0];
            const technical_id = selectedTechnical[0];
    
            try {
                await buildAndShowMap(weekday_id, routine_id, station_id, technical_id);
            } catch (error) {
                console.error(error);
            }
        };
    
        const buildAndShowMap = async (weekday_id: string, routine_id: string, station_id: string, technical_id: string) => {
            try {
                const responseJson = await api.get(`/api/getAllMapPoints/${weekday_id}/${routine_id}/${station_id}/${technical_id}`);
                const responseData = responseJson.data;
    
                if (!responseData || !responseData.data) {
                    setMapVisible(false);
                } else {
                    const positions = responseData.data.positions || [];
                    const activityPositionsArray = responseData.data.activityPositions || [];
                    if (positions.length === 0 || activityPositionsArray.length === 0) {
                        setMapVisible(false);
                    } else {
                        showGoogleMap(positions, activityPositionsArray);
                        setMapVisible(true);
                    }
                }
            } catch (error) {
                console.error('Error building map:', error);
            }
        };
    
        const showGoogleMap = async (positions: any[], activityPositions: any[]) => {
            if (!positions || !activityPositions) {
                console.error('Positions / activityPositions está vazio');
                return;
            }
    
            try {
                const positionsCoordinates = positions[0].map((position: { latitude: any; longitude: any }) => ({
                    lat: position.latitude,
                    lng: position.longitude
                }));
                setPositions(positionsCoordinates);
    
                const activityCoordinates = activityPositions[0].map((position: {
                    latitude: any;
                    longitude: any;
                    status: any;
                    nome: any;
                }) => ({
                    lat: position.latitude,
                    lng: position.longitude,
                    status: position.status,
                    nome: position.nome
                }));
    
                setActivities(activityCoordinates);
            } catch (error) {
                console.error('Error parsing positions or activityPositions:', error);
            }
        };
    
        return (
           
                <Box sx={{ margin: '0px 78px', display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
                    <h2 className="fw-bold mb-2" style={{ margin: '20px auto 20px', padding: '0', textAlign: 'center' }}>Mapa</h2>
                    <Box sx={{ display: 'flex', gap: '10px', marginTop: 5, width: '100%',  justifyContent: 'center' }}>
                        <Select
                            styles={customStyles}
                            options={stations.map((station) => ({ value: station.id, label: station.name }))}
                            placeholder={inputName[0]}
                            onChange={(selectedOptions: any) => setSelectedStations(selectedOptions ? [selectedOptions.value] : [])}
                            isClearable
                        />
                        <Select
                            styles={customStyles}
                            options={technical.map((tech) => ({ value: tech.id, label: tech.name }))}
                            placeholder={inputName[1]}
                            onChange={(selectedOptions: any) => setSelectedTechnical(selectedOptions ? [selectedOptions.value] : [])}
                            isClearable
                        />
                        <Select
                            styles={customStyles}
                            options={routines.map((routine) => ({ value: routine.id, label: routine.name }))}
                            placeholder={inputName[2]}
                            onChange={(selectedOptions: any) => setSelectedRoutines(selectedOptions ? [selectedOptions.value] : [])}
                            isClearable
                        />
                        <Select
                            styles={customStyles}
                            options={Object.entries(weekDayNames).map(([id, name]) => ({ value: id, label: name }))}
                            placeholder={inputName[3]}
                            onChange={handleWeekdayChange}
                            isClearable
                        />
                        <Button
                            sx={{
                                height: '38px',
                                backgroundColor: '#111c5d',
                                ':hover': {
                                    backgroundColor: '#122079',
                                }
                            }}
                            variant="contained"
                            size="small"
                            type="button"
                            className="btn btn-primary"
                            onClick={handleButtonMap}
                        >
                            Filtrar
                        </Button>
                    
               
                
                {/* Map section positioned at the bottom */}
                {mapVisible && positions.length > 0 && activities.length > 0 ? (
                     <div style={{
                        position: 'absolute',
                        top: '35%', // adjusted to move the container up
                        left: '56%',
                        transform: 'translateX(-47%)', // to center horizontally
                        width: '68%',
                        margin: 'auto',
                        height: '535px',
                        borderRadius: '10px',
                        padding: '20px',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                      }}>
                        <MapContainer positions={positions} activities={activities} />
                      </div>
            
                ) : (
    
                    <div style={{
                        position: 'absolute',
                        top: '35%', // adjusted to move the container up
                        left: '56%',
                        transform: 'translateX(-47%)', // to center horizontally
                        width: '68%',
                        margin: 'auto',
                        height: '535px',
                        borderRadius: '10px',
                        padding: '20px',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                      }}>
                        <MapContainer positions={positions} activities={activities} />
                      </div>
    
    
                )}
                </Box>
                </Box>
        );
    }


