import React from "react";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

interface MenuProps {
  label: string;
  to: string;
}

export const Menu: React.FC<MenuProps> = ({ label, to }) => {
  const location = useLocation();
  const navigate = useNavigate();

  function removeAccentsAndSpecialChars(str: string) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase();
  }

  const isActive = removeAccentsAndSpecialChars(location.pathname) === removeAccentsAndSpecialChars(to);


  const activeStyle = {
    backgroundColor: '#131F6F',
    color: 'white',
  };

  const buttonStyle = isActive ? activeStyle : {
    width: "100%",
    color: 'white',
    justifyContent: "flex-start",
  };
  const handleButtonClick = () => {
    navigate(to);
  };

  return (
    <Button
      sx={buttonStyle}
      onClick={handleButtonClick}
    >
      {label}
    </Button>
  );
};