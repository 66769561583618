import React, { useState } from 'react';
import { GoogleMap, useLoadScript, MarkerF, Polyline, InfoWindowF } from '@react-google-maps/api';

interface Location {
    lat: number;
    lng: number;
    status: string;
    nome: string;
}

interface MapContainerProps {
    positions: any;
    activities: any;
}

const MapContainer: React.FC<MapContainerProps> = ({ positions, activities }) => {
console.log("🚀 ~ activities:", activities)
console.log("🚀 ~ positions:", positions)

    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const [areMarkersRendered, setAreMarkersRendered] = useState(false);
    const [selectedMarker, setSelectedMarker] = useState<Location | null>(null);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyA9MjROBJTSX0CPsWOaTtYjvbFXORbLRGU',
    });

    const handleMapLoad = () => {
        setIsMapLoaded(true);
    };

    const handleMarkersLoad = () => {
        setAreMarkersRendered(true);
    };

    const markerColor = "blue";

    if (loadError) return <div>Erro ao carregar o Google Maps</div>;
    if (!isLoaded) return <div>Carregando o mapa...</div>;

    if (!positions || !activities || positions.length === 0 || activities.length === 0) {
        return <p>Esta rotina não inclui um mapa para o dia selecionado!</p>;
    }

    return (
        <GoogleMap
            mapContainerStyle={{ width: '1265px', height: '500px' }}
            zoom={17}
            center={positions.length > 0 ? positions[0] : { lat: 0, lng: 0 }}
            onLoad={handleMapLoad}
        >
            {activities.map((location: Location, i: number) => (
                <MarkerF
                    key={i}
                    position={location}
                    onLoad={handleMarkersLoad}
                    onClick={() => setSelectedMarker(location)}
                    options={{
                        icon: {
                            url: location.status === "0"
                                ? "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
                                : location.status === "1"
                                    ? "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
                                    : "http://maps.google.com/mapfiles/ms/icons/orange-dot.png",
                            scaledSize: new window.google.maps.Size(40, 40),
                            fillOpacity: 1,
                            strokeWeight: 0,
                        },
                    }}
                >
                    {selectedMarker === location && (
                        <InfoWindowF
                            onCloseClick={() => setSelectedMarker(null)}
                        >
                            <div style={{
                                maxWidth: '200px',
                                backgroundColor: 'white',
                                borderRadius: '5px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                padding: '5px',
                                fontSize: '12px'
                            }}>
                                <h3 style={{ marginBottom: '5px', fontSize: '23px', textAlign: 'center' }}>{selectedMarker.nome}</h3>
                                <p style={{ marginBottom: '5px' }}>Lat: {selectedMarker.lat}</p>
                                <p style={{ marginBottom: '5px' }}>Lng: {selectedMarker.lng}</p>
                                {selectedMarker.status == '1' ? (
                                    <p>Status: Dentro dos Padrões</p>
                                ) : selectedMarker.status == '2' ? (
                                    <p>Status: Indisponível</p>
                                ) : (
                                    <p>Status: Fora dos Padrões</p>
                                )}

                            </div>
                        </InfoWindowF>
                    )}
                </MarkerF>
            ))}
            <MarkerF
                position={positions[0]}
                onLoad={handleMarkersLoad}
                onClick={() => setSelectedMarker(positions[0])}
                options={{
                    icon: {
                        url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                        scaledSize: new window.google.maps.Size(40, 40),
                        fillColor: markerColor,
                        fillOpacity: 1,
                        strokeWeight: 0,
                    },
                }}
            >
                {selectedMarker === positions[0] && (
                    <InfoWindowF
                        onCloseClick={() => setSelectedMarker(null)}
                    >
                        <div style={{
                            maxWidth: '200px',
                            backgroundColor: 'white',
                            borderRadius: '5px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            padding: '5px',
                            fontSize: '12px'
                        }}>
                            <h3 style={{ marginBottom: '5px', fontSize: '23px', textAlign: 'center' }}>Início</h3>
                            <p>Lat: {positions[0].lat}</p>
                            <p>Lng: {positions[0].lng}</p>
                        </div>
                    </InfoWindowF>
                )}
            </MarkerF>
            <MarkerF
                position={positions[positions.length - 1]}
                onLoad={handleMarkersLoad}
                onClick={() => setSelectedMarker(positions[positions.length - 1])}
                options={{
                    icon: {
                        url: "http://maps.google.com/mapfiles/ms/icons/pink-dot.png",
                        scaledSize: new window.google.maps.Size(40, 40),
                        fillColor: markerColor,
                        fillOpacity: 1,
                        strokeWeight: 0,
                    },
                }}
            >
                {selectedMarker === positions[positions.length - 1] && (
                    <InfoWindowF
                        onCloseClick={() => setSelectedMarker(null)}
                    >
                        <div style={{
                            maxWidth: '200px',
                            backgroundColor: 'white',
                            borderRadius: '5px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            padding: '5px',
                            fontSize: '12px'
                        }}>
                            <h3 style={{ marginBottom: '5px', fontSize: '23px', textAlign: 'center' }}>Fim</h3>
                            <p>Lat: {positions[positions.length - 1].lat}</p>
                            <p>Lng: {positions[positions.length - 1].lng}</p>
                        </div>
                    </InfoWindowF>
                )}
            </MarkerF>
            {isMapLoaded && areMarkersRendered && (
                <Polyline
                    path={positions}
                    options={{ strokeColor: '#000000', strokeWeight: 2, strokeOpacity: 1.0 }}
                />
            )}
        </GoogleMap>
    );
};

export default MapContainer;
