export enum WeekDay  {
    Segunda = 1,
    Terca = 2,
    Quarta = 3,
    Quinta = 4,
    Sexta = 5,
    Sabado = 6,
    Domingo = 7,
  }
  
  export const weekDayNames: Record<string, string> = {
    [WeekDay.Segunda]: 'Segunda-Feira',
    [WeekDay.Terca]: 'Terça-Feira',
    [WeekDay.Quarta]: 'Quarta-Feira',
    [WeekDay.Quinta]: 'Quinta-Feira',
    [WeekDay.Sexta]: 'Sexta-Feira',
    [WeekDay.Sabado]: 'Sabado',
    [WeekDay.Domingo]: 'Domingo',
  };
  