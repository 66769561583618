import React, { useState } from "react";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import { EditField } from "./EditField";
import { weekDayNames } from "../../utils/enums/WeekDay";

interface EditFormProps {
    items: any;
    labels: string[];
    title: string;
    itemData: { [key: string]: any }[];
    onSave: (updatedData: Object) => void;
}

const urls = {
    station: "/api/getAllStation",
    types: "/api/getAllTypes",
    activityTypes: "/api/getAllActivityTypes",
    equipments: "/api/getAllEquipments",
    routine: "/api/getAllRoutine",
    activity: "/api/getAllActivities",
    technical: "/api/getAllTechnical",
    activityStatus: "/api/getAllActivityStatus",
};

const getUrl = (label: string, title: string) => {
    switch (title) {
        case 'equipamentos':
            if (label === 'Estação') return urls.station;
            if (label === 'Tipo') return urls.types;
            break;
        case 'usuario':
            if (label === 'Estação') return urls.station;
            break;
        case 'atividades':
            if (label === 'Tipo') return urls.activityTypes;
            if (label === 'Equipamento') return urls.equipments;
            break;
        case 'agendamentos':
            if (label === 'Estação') return urls.station;
            if (label === 'Rotina') return urls.routine;
            if (label === 'Atividade') return urls.activity;
            if (label === 'Tipo Atividade') return urls.activityTypes;
            if (label === 'Técnico') return urls.technical;
            if (label === 'Status') return urls.activityStatus;
            break;
        default:
            return null;
    }
    return null;
};


export const EditForm: React.FC<EditFormProps> = ({ labels, itemData, title, onSave }) => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    const handleSave = async () => {
        setLoading(true);
        try {
            await onSave(data);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (label: string, value: string) => {
        setData((prevData) => ({
            ...prevData,
            [label]: value,
        }));
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {itemData.map((dataItem, index) => (
                <div key={index} style={{ marginBottom: '10px' }}>
                    {labels.map((label) => {
                        const url = getUrl(label, title);
                        return (
                            <div key={label}>
                                {label === 'Dia/Semana' ? (
                                    <TextField
                                    label={label}
                                    select
                                    size="small"
                                    defaultValue={dataItem[label]}
                                    onChange={(e) => handleChange(label, e.target.value)}
                                    sx={{ marginBottom: 2, width: '100%' }}
                                >
                                    {Object.entries(weekDayNames).map(([key, value]) => (
                                        <MenuItem key={key} value={key}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                ) : url ? (
                                    <EditField
                                        label={label}
                                        itemData={itemData}
                                        setData={setData}
                                        url={url}
                                    />
                                ) : (
                                    <TextField
                                        label={label}
                                        size='small'
                                        defaultValue={dataItem[label]}
                                        onChange={(e) => handleChange(label, e.target.value)}
                                        sx={{ marginBottom: 2, width: '100%' }}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
            ))}
            <Button
                sx={{
                    backgroundColor: '#111c5d',
                    color: 'white',
                    ':hover': {
                        backgroundColor: '#122079',
                    }
                }}
                onClick={handleSave}
                disabled={loading}
            >
                {loading ? 'Salvando...' : 'Salvar'}
            </Button>
        </Box>
    );
};