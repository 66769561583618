import { useState, useEffect } from "react";
import { Box, MenuItem, TextField } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import api from "../../services/api";
import Select from 'react-select';
import { customStyles } from "./styles";
import { useNavigate } from "react-router-dom";

interface SelectedOption {
  label: string;
  value: number;
}

interface Routine {
  id: string;
  name: string;
}

export function AssignActivities() {
  const [resetInputs, setResetInputs] = useState(false);
  const [isRoutineSelected, setIsRoutineSelected] = useState(false);
  const [activities, setActivities] = useState<SelectedOption[]>([]);
  const [allActivities, setAllActivities] = useState<SelectedOption[]>([]);
  const [routines, setRoutines] = useState<Routine[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<SelectedOption[]>([]);
  const [deletedActivitiesArray, setDeletedActivitiesArray] = useState<any[]>([]);
  const [selectKey, setSelectKey] = useState(0);
  const [selectedRoutineId, setSelectedRoutineId] = useState<string>('');
  const navigate = useNavigate();

  async function saveAssignActivities() {
    try {
      const routineActivityArray = selectedOptions.map((item: SelectedOption) => ({
        routineId: selectedRoutineId,
        activityId: item.value,
      }));
      console.log("🚀 ~ routineActivityArray ~ routineActivityArray:", routineActivityArray)

      const responseRoutineActivity = await api.post("/api/assignRoutinesWithActivities", {
        routineActivityArray: routineActivityArray,
        deletedActivitiesArray: deletedActivitiesArray,
      });

      if (responseRoutineActivity.status === 200) {
        setSelectedRoutineId('');
        setAllActivities([]);
        setSelectedOptions([]);
        setDeletedActivitiesArray([]);
        setSelectKey((prevKey) => prevKey + 1);
        setIsRoutineSelected(true);
        setResetInputs((prevResetInputs) => !prevResetInputs);

        toast.success("Atividades atribuídas com sucesso!");
      } else {
        toast.error("Erro ao atribuir atividades, tente novamente!");
      }
    } catch (error: any) {
      console.error('Erro ao atribuir atividades:', error);
      toast.error("Erro ao atribuir atividades, tente novamente!");
    }
  }

  const handleRoutineChange = async (routine_id: string) => {
    try {
      setSelectedRoutineId(routine_id);
      setIsRoutineSelected(false);

      const responseAllActivities = await api.get<{ id: string; name: string }[]>("/api/getAllActivities");

      const allActivitiesData = responseAllActivities.data;

      const formattedActivities = allActivitiesData.map((activity) => ({
        value: Number(activity.id),
        label: activity.name,
      }));

      setActivities(formattedActivities);
      setAllActivities(formattedActivities);
      setIsRoutineSelected(true);

    } catch (error: any) {
      console.error("Erro ao selecionar rotina:", error);
      toast.error("Erro ao selecionar rotina, tente novamente!");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseRoutine = await api.get<Routine[]>(`/api/getAllRoutine`);
        setRoutines(responseRoutine.data);
      } catch (error: any) {
        console.error('Erro ao buscar rotinas:', error);
        toast.error("Erro ao buscar rotinas, tente novamente!");
      }
    };

    fetchData();
  }, [resetInputs]);

  const handleSelectChange = (selectedOptions: SelectedOption[]) => {
    const removedActivities = allActivities.filter(
      (activity) => !selectedOptions.some((selected) => selected.value === activity.value)
    );

    const deletedActivitiesArray = removedActivities.map((activity) => ({
      routineId: selectedRoutineId,
      value: activity.value
    }));

    setDeletedActivitiesArray(deletedActivitiesArray);
    setSelectedOptions(selectedOptions);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: '100%',
          borderWidth: 10,
          borderColor: '#efefef',
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
          <Box sx={{ borderStyle: 'solid', borderWidth: 1, borderColor: '#111c5d', padding: 2, borderRadius: 3, width: '500px', flexDirection: 'column', position: 'relative', alignItems: 'center', justifyContent: 'center' }}>
            <span style={{ position: 'absolute', top: -14, left: 10, backgroundColor: '#FFF', padding: '0 5px', color: '#111c5d', zIndex: 1 }}>
              Atribuir Atividades
            </span>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
              <div style={{ marginBottom: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <Box sx={{ flexDirection: 'column' }}>
                    <TextField
                      select
                      sx={{ minWidth: '466px', maxWidth: '45%' }}
                      label="Rotina"
                      value={selectedRoutineId}
                      onChange={(e) => handleRoutineChange(e.target.value)}
                      size='small'
                    >
                      {routines.map((routine) => (
                        <MenuItem key={routine.id} value={routine.id}>
                          {routine.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    {isRoutineSelected && (
                      activities.length > 0 && (
                        <Select
                          key={selectKey}
                          isMulti
                          options={activities}
                          value={selectedOptions}
                          styles={customStyles}
                          onChange={(selectedOptions) => handleSelectChange(selectedOptions as SelectedOption[])}
                          placeholder="Atividades"
                        />
                      )
                    )}
                  </Box>
                </div>
              </div>
            </div>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <button
                onClick={saveAssignActivities}
                type="button"
                className="btn btn-primary"
                style={{ backgroundColor: '#111c5d', width: 100, height: 30, borderRadius: 5, padding: 0, border: 'none' }}
              >
                <p style={{ fontSize: 12, margin: 0 }}>SALVAR</p>
              </button>
              <button
                onClick={() => navigate(`/details/usuario`)}
                type="button"
                className="btn btn-primary"
                style={{ backgroundColor: '#111c5d', width: 100, height: 30, borderRadius: 5, padding: 0, border: 'none' }}
              >
                <p style={{ fontSize: 12, margin: 0 }}>PRÓXIMO</p>
              </button>
            </Box>
          </Box>
        </Box>
      </Box>
      <ToastContainer />
    </>
  );
}
