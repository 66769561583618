import { useState, useEffect } from "react";
import { Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import api from "../../services/api";
import Select from 'react-select';
import { customStyles } from "./styles";
import { useNavigate } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { Modal } from "react-bootstrap";
import { ModalEquipament } from "../ModalEquipament";

export function Historico() {
    const [stations, setStations] = useState<any[]>([]);
    const [technical, setTechnical] = useState<any[]>([]);
    const [data, setData] = useState<any[]>([]);
    const [activities, setActivities] = useState<any[]>([]);
    const [selectedStations, setSelectedStations] = useState<any[]>([]);
    const [selectedTechnical, setSelectedTechnical] = useState<any[]>([]);
    const [selectedActivities, setSelectedActivities] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [schedulingId, setSchedulingId] = useState<string>('');
    const [equipamentId, setEquipamentId] = useState<string>('');
    const [showModalls, setShowModalls] = useState(false);
    const navigate = useNavigate();

    const inputName: string[] = ["Estação", "Rotina", "Atividade", "Tipo Atividade", "Técnico", "Dia/Semana", "Data/Realização", "Status"];
    const thead: string[] = ["Estação", "Rotina", "Atividade", "Tipo Atividade", "Técnico", "Dia/Semana", "Data/Realização", "Status"];

    const formatDateTime = (dateTimeString: string | number | Date) => {
        if (!dateTimeString) {
            // Retorne uma string vazia ou outro valor padrão para campos sem data
            return "";
        }
    
        const options: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        };
    
        const dateTime = new Date(dateTimeString);
        return dateTime.toLocaleString('pt-BR', options);
    };

    const handleVisualizarClick = (routine_id: number, station_id: number, technical_id: number) => {
        const selectedData = data.find((item) => item.routine_id === routine_id && item.station_id === station_id && item.technical_id === technical_id);
        navigate(`/homeDetails/${selectedData.routine_id}/${selectedData.station_id}/${selectedData.technical_id}`, { state: [selectedData] });
    };

    

    const handleFilter = async () => {
        setIsLoading(true);
        try {
            const response = await api.post('/api/getAllDataFromRoutineHistory', {
                stations: selectedStations.length ? selectedStations : null,
                technicals: selectedTechnical.length ? selectedTechnical : null,
                activities: selectedActivities.length ? selectedActivities : null,
            });

            const filteredData = response.data.map((item: any) => ({
                Estação: item.station_name,
                Rotina: item.routine_name,
                Atividade: item.activity_name,
                "Tipo Atividade": item.activity_type,
                Técnico: item.technical_name,
                "Dia/Semana": item.weekday_name,
                "Data/Realização": formatDateTime(item.completion_date),
                Status: item.status,
                routine_id: item.routine_id,
                station_id: item.station_id,
                technical_id: item.technical_id,
                activity_id: item.activity_id,
            }));

            setData(filteredData);
        } catch (error) {
            console.error('Error filtering data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const responseStation = await api.get<{ id: string, name: string }[]>('/api/getAllStation');
                const responseTechnical = await api.get<{ id: string, name: string }[]>('/api/getAllTechnical');
                const responseActivities = await api.get<{ id: string, name: string }[]>('/api/getAllActivities');
                const responseData = await api.post('/api/getAllDataFromRoutineHistory');

                const formattedData = responseData.data.map((item: any) => ({
                    Estação: item.station_name,
                    Rotina: item.routine_name,
                    Atividade: item.activity_name,
                    "Tipo Atividade": item.activity_type,
                    Técnico: item.technical_name,
                    "Dia/Semana": item.weekday_name,
                    "Data/Realização": formatDateTime(item.completion_date),
                    Status: item.status,
                    routine_id: item.routine_id,
                    station_id: item.station_id,
                    technical_id: item.technical_id,
                    activity_id: item.activity_id,
                }));

                setData(formattedData);
                setTechnical(responseTechnical.data);
                setStations(responseStation.data);
                setActivities(responseActivities.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const generateDateOptions = () => {
        const dates = [];
        for (let i = 0; i < 365; i++) {
          const date = moment().add(i, 'days').format('YYYY-MM-DD');
          dates.push({ value: date, label: date });
        }
        return dates;
      };

      const handleVisualizarEquipamentos = (routine_id: number, station_id: number, technical_id: number, scheduling_id: number, weekday_id: number) => {
        const selectedData = data.find((item) => 
          item.routine_id === routine_id && 
          item.station_id === station_id && 
          item.technical_id === technical_id &&
          item.scheduling_id === scheduling_id &&
          item.weekday_id === weekday_id 
        );
      
        if (selectedData) {
          setSchedulingId(selectedData.scheduling_id || selectedData.id);
          setEquipamentId(selectedData.equipment_id || selectedData.equipment) // Use the correct data field
          setShowModalls(true); // Assuming this controls the modal visibility
        }
      };

    // Filter data based on selected date range
    const filteredData = data.filter(item => {
        const itemDate = moment(item.data);
        const isAfterStartDate = startDate ? itemDate.isSameOrAfter(moment(startDate)) : true;
        const isBeforeEndDate = endDate ? itemDate.isSameOrBefore(moment(endDate)) : true;
        return isAfterStartDate && isBeforeEndDate;
    });



    const handleDeleteItem = async (item: any) => {
        try {
          const responseJson = await api.post(`/api/deleteItemData/${item}/`);
          deleteData(item)
        } catch (error) {
          toast.error("O item não pode ser excluido pois ele está sendo referenciado à outro item!")
        }
      };

      const deleteData = (selectedItem: any) => {
        let index = 0
        let originalObj: any[] = data.filter((item: any) => item.id !== selectedItem)
        setData(originalObj)
      }

    return (
        <Box sx={{ margin: '0 0 0 30px', display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
            <h2 className="fw-bold mb-2" style={{ margin: '20px 0 20px 0', padding: '0' }}>Histórico</h2>
            <Box sx={{ display: 'flex', gap: '10px', marginTop: 5, width: '100%' }}>
                <Select
                    isMulti
                    styles={customStyles}
                    options={stations.map((station) => ({ value: station.id, label: station.name }))}
                    placeholder={inputName[0]}
                    onChange={(selectedOptions) => setSelectedStations(selectedOptions ? selectedOptions.map((option) => option.value) : [])}
                />
                <Select
                    isMulti
                    styles={customStyles}
                    options={technical.map((tech) => ({ value: tech.id, label: tech.name }))}
                    placeholder={inputName[4]}
                    onChange={(selectedOptions) => setSelectedTechnical(selectedOptions ? selectedOptions.map((option) => option.value) : [])}
                />
                <Select
                    isMulti
                    styles={customStyles}
                    options={activities.map((activity) => ({ value: activity.id, label: activity.name }))}
                    placeholder={inputName[2]}
                    onChange={(selectedOptions) => setSelectedActivities(selectedOptions ? selectedOptions.map((option) => option.value) : [])}
                />
             <Select
                    styles={customStyles}
                    options={generateDateOptions()}
                    placeholder="Data Início"
                    onChange={(selectedOption) => setStartDate(selectedOption?.value ?? '')}
                    />

                    <Select
                    styles={customStyles}
                    options={generateDateOptions()}
                    placeholder="Data Fim"
                    onChange={(selectedOption) => setEndDate(selectedOption?.value ?? '')}
                    />


                <Button
                    sx={{
                        height: '38px',
                        backgroundColor: '#111c5d',
                        ':hover': {
                            backgroundColor: '#122079',
                        }
                    }}
                    variant="contained"
                    size="small"
                    type="button"
                    onClick={handleFilter}
                >
                    Filtrar
                </Button>
            </Box>

            {isLoading ? (
                <Box sx={{ textAlign: 'center', marginTop: 5 }}>
                    Carregando dados...
                </Box>
            ) : (
                <TableContainer component={Paper} sx={{ maxHeight: '50vh', marginTop: 5, marginBottom: 2, width: '98%' }}>
                    <Table size="medium" aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {thead.map((item, index) => (
                                    <TableCell key={index}>{item}</TableCell>
                                ))}
                                <TableCell>Equipamentos</TableCell><TableCell>Excluir</TableCell>
                            </TableRow>
                            
                        </TableHead>
                        <TableBody>
                            {filteredData.length > 0 && (
                                filteredData.map((item: any, index: number) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        {thead.map((label, labelIndex) => (
                                            <TableCell key={`${index}-${labelIndex}`}>{item[label]}</TableCell>
                                        ))}
                                       <TableCell key={`action-${index}`}>
                                        {(item.activity_type === 2 || item.type === "2") && (
                                            <Button
                                                sx={{ height: '30px', backgroundColor: '#111c5d' }}
                                                variant={'contained'}
                                                size='small'
                                                type='button'
                                                className='btn btn-primary'
                                                onClick={() => handleVisualizarEquipamentos(item.routine_id, item.station_id, item.technical_id, item.scheduling_id, item.weekday_id)}
                                            >
                                                Equipamentos
                                            </Button>
                                        )}
                                    </TableCell>
                                        <TableCell key={`delete-${index}`}>
                                        <IconButton
                                            onClick={() => handleDeleteItem(item.id)} // Assuming 'id' is the identifier for the item
                                            aria-label="delete"
                                            sx={{
                                                color: '#d32f2f', // Optional: Change the color for the delete icon
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

                <Modal show={showModalls} onHide={() => setShowModalls(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Equipamentos</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <ModalEquipament data={{ schedulingId: schedulingId, equipamentId: equipamentId }} />
                        </Modal.Body>
                    </Modal>
            <ToastContainer />
            
        </Box>
    );
}
function setShowModalls(arg0: boolean) {
    throw new Error("Function not implemented.");
}

