import { useState, useEffect } from "react";
import api from "../../services/api";
import { WeekDay, weekDayNames } from '../../utils/enums/WeekDay';
import { Box, MenuItem} from "@mui/material";
import TextField from '@mui/material/TextField';
import { toast } from "react-toastify";
import Select from 'react-select';
import { customStylesTwo } from "../AssignRoutine/styles";

interface SelectedOption {
  label: string;
  value: number;
}

interface IStations {
  id: number;
  name: string;
  location: string;
  latitude: string;
  longitude: string;
  createdAt: Date;
  updatedAt: Date;
}

interface IRoutines {
  [x: string]: any;
  id: number;
  name: string;
}

interface ITechnical {
  id: number;
  name: string;
  email: string;
  user_id: number;
  station_id: number;
  createdAt: Date;
  updatedAt: Date;
}

interface IActivity {
  [x: string]: any;
  id: number;
  name: string;
  type: string;
}

export const Agendamentos2 = () => {
  const [stationsData, setStationsData] = useState<IStations[]>([]);
  const [technicalData, setTechnicalData] = useState<ITechnical[]>([]);
  const [routinesData, setRoutinesData] = useState<IRoutines[]>([]);
  const [activityData, setActivityData] = useState<IActivity[]>([]);
  const [filteredRoutinesData, setFilteredRoutines] = useState<IRoutines[]>([]);
  const [filteredActivitiesData, setFilteredActivities] = useState<IActivity[]>([]);
  const [activityStatusData, setActivityStatusData] = useState<{ id: string, name: string }[]>([]);
  const [stationId, setStationId] = useState<string>('');
  const [technicalId, setTechnicalId] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState<SelectedOption[]>([]);
  const [deletedActivitiesArray, setDeletedActivitiesArray] = useState<any[]>([]);
  const [deletedRoutinesArray, setDeletedRoutinesArray] = useState<any[]>([]);
  const [routineId, setRoutineId] = useState<string>('');
  const [dayId, setDayId] = useState<string[]>([]);
  const [dayIds, setDaysId] = useState<number | ''>('');
  const [activityId, setActivityId] = useState<number[]>([]);
  const [activityStatusId, setActivityStatusId] = useState<string>('');
  const nomedaentrada: string[] = ["Atividade"];
  let activityStatusIds = "3";

  async function SaveAgendamento() {
    // Verifica se todos os campos obrigatórios estão preenchidos
    const fields = [
      { value: stationId, message: "Por favor, selecione uma estação." },
      { value: routineId, message: "Por favor, selecione uma rotina." },
      { value: technicalId, message: "Por favor, consulte um técnico." },
      { value: dayId.length, message: "Por favor, selecione pelo menos um dia." },
      { value: activityId.length, message: "Por favor, selecione pelo menos uma atividade." },
    ];

    for (const field of fields) {
      if (!field.value) {
        toast.error(field.message);
        setStationId('');
        setTechnicalId('');
        setRoutineId('');
        setDayId([]);
        setActivityId([]);
        setActivityData([]);
        setSelectedOptions([]); // Limpa o estado selectedOptions
        setDeletedActivitiesArray([]);
        setActivityStatusId('');
        setFilteredActivities([]); // Limpa o filtro de atividades
        setFilteredRoutines([]);
        return;
      }
    }

    try {
      console.log('Iniciando o salvamento do agendamento');
      console.log('dayId:', dayId);
      console.log('activityId:', activityId);

      // Itera sobre cada atividade selecionada
      for (const activity of activityId) {
        // Itera sobre cada dia selecionado
        for (const day of dayId) {
          const requestData = {
            stationId: String(stationId),
            routineId: String(routineId),
            activityId: String(activity),
            technicalId: String(technicalId),
            weekdayId: String(day),
            activityStatusId: String(activityStatusIds),
          };

          console.log('Dados enviados para a API:', requestData);
          const response = await api.post("/api/addScheduling", requestData);
          console.log('Resposta da API:', response);

          if (response.status !== 200 && response.status !== 201) {
            console.error(`Falha ao agendar atividade ${activity} para o dia ${day}: ${response.statusText}`);
            toast.error(`Erro ao cadastrar agendamento para atividade ${activity } no dia ${day}.`);
            return;
          }
        }
      }

      console.log('Agendamento salvo com sucesso');
      setStationId('');
  setTechnicalId('');
  setRoutineId('');
  setDayId([]);
  setActivityId([]);  // Limpa as atividades selecionadas
  setSelectedOptions([]);  // Limpa o estado de selectedOptions para o Select
  setActivityData([]);
  setDeletedActivitiesArray([]);
  setActivityStatusId('');
  setFilteredActivities([]); // Limpa o filtro de atividades
  setFilteredRoutines([]);  // Limpa o filtro de rotinas  
      toast.success("Agendamento cadastrado com sucesso para todas as atividades e dias selecionados!");
    } catch (error: any) {
      console.error('Erro ao salvar agendamento:', error.response?.data || error.message || error);
      toast.error("Erro ao cadastrar o agendamento, por favor tente novamente!");
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseStations = await api.get<IStations[]>("/api/getAllStation");
        const responseTechnical = await api.get<ITechnical[]>("/api/getAllTechnical");
        const responseRoutines = await api.get<IRoutines[]>("/api/getAllRoutine");
        const responseActivity = await api.get<IActivity[]>("/api/getAllActivities");
        const responseActivityStatus = await api.get<{ id: string, name: string }[]>("/api/getAllActivityStatus");

        setStationsData(responseStations.data);
        setTechnicalData(responseTechnical.data);
        setRoutinesData(responseRoutines.data);
        setActivityData(responseActivity.data);
        setActivityStatusData(responseActivityStatus.data);
      } catch (error: any) {
        console.error('Erro ao buscar dados:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchStationRoutines = async () => {
      if (stationId) {
        try {
          const responseDataTeste = await api.get<IRoutines[]>(`/api/getAllStationRoutine/${stationId}`);
          setFilteredRoutines(responseDataTeste.data);
        } catch (error: any) {
          console.error("Erro ao buscar rotinas de estação:", error);
          setFilteredRoutines([]);
        }
      } else {
        setFilteredRoutines([]);
      }
    };

    fetchStationRoutines();
  }, [stationId]);

  useEffect(() => {
    const fetchRoutinesActivity = async () => {
      if (routineId) {
        try {
          // Limpa o filtro de atividades antes de buscar novas atividades
          setFilteredActivities([]);
          const responseDataTesteA = await api.get<IActivity[]>(`/api/getActivityList/${routineId}`);
          setFilteredActivities(responseDataTesteA.data);
        } catch (error: any) {
          console.error("Erro ao buscar atividades da rotina:", error);
          setFilteredActivities([]);
        }
      } else {
        setFilteredActivities([]);
      }
    };

    fetchRoutinesActivity();
  }, [routineId]);

  const handleSelectChanges = (selectedOptions: SelectedOption[]) => {
    const removedActivities = filteredActivitiesData.filter((activity) => !selectedOptions.some((selected) => selected.value === activity.activity_id));
    const deletedActivitiesArray = removedActivities.map((activity) => ({ routineId: routineId, value: activity.value }));
    setDeletedActivitiesArray(deletedActivitiesArray);
    setSelectedOptions(selectedOptions);
  };

  const handleSelectChange = (selectedOptions: any) => {
    const removedRoutines = filteredActivitiesData.filter((routine) => !selectedOptions.some((selected: any) => selected.value === routine.value));
    const deletedRoutinesArray = removedRoutines.map((routine) => ({ stationId: stationId, value: routine.value }));
    setDeletedRoutinesArray(deletedRoutinesArray);
    setSelectedOptions(selectedOptions);
  };

  const handleDayChange = (selectedOptions: { value: string }[]) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setDayId(selectedValues);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: '100%', borderWidth: 10, borderColor: '#efefef' }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <TextField
          select
          sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
          label={'Estação'}
          size='small'
          value={stationId}
          onChange={(e) => setStationId(e.target.value)}
        >
          {stationsData.map((station) => (
            <MenuItem key={station.id} value={station.id}>{station.name}</MenuItem>
          ))}
        </TextField>

        <TextField
          select
          sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
          label={' Usuário'}
          size='small'
          value={technicalId}
          onChange={(e) => setTechnicalId(e.target.value)}
        >
          {technicalData.map((tech) => (
            <MenuItem key={tech.id} value={tech.id}>{tech.name}</MenuItem>
          ))}
        </TextField>

        <TextField
          select
          sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
          label={'Rotina'}
          size='small'
          value={routineId}
          onChange={(e) => setRoutineId(e.target.value)}
        >
          {filteredRoutinesData.map((routine) => (
            <MenuItem key={routine.routine_id} value={routine.routine_id}>{routine.name}</MenuItem>
          ))}
        </TextField>

        <Box sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px', size: 'small' }}>
          <Select
            isMulti
            styles={customStylesTwo}
            options={filteredActivitiesData.map((activity) => ({ value: activity.activity_id, label: activity.activity_name }))}
            placeholder={'Atividade'}
            onChange={(selectedOptions: any) => {
              setActivityId(selectedOptions.map((option: { value: any; }) => option.value));
              setSelectedOptions(selectedOptions);
            }}
            value={selectedOptions}  // Adicione esta linha para controlar o valor selecionado
          />
        </Box>


        <Box sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px', size: 'small' }}>
          <Select
            isMulti
            styles={customStylesTwo}
            options={Object.entries(weekDayNames).map(([id, name]) => ({ value: id, label: name }))}
            placeholder={'Dia da Semana'}
            onChange={(selectedOptions: any) => handleDayChange(selectedOptions)}
            value={dayId.map((id) => ({ value: id, label: weekDayNames[id] }))}
          />
        </Box>

        <Box sx={{ display: "flex", justifyContent: 'flex-end', width: '100%', marginY: '20px' }}>
          <button
            onClick={SaveAgendamento}
            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#122079'}
            onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#111c5d'}
            type="button"
            className="btn btn-primary"
            style={{
              backgroundColor: '#111c5d',
              width: 100,
              height: 40,
              borderRadius: 5,
              bottom: 10,
              right: 10,
              padding: 0,
              alignSelf: 'flex-end',
              fontSize: 20,
              border: 'none'
            }}
          >
            <p style={{ fontSize: 16, margin: 0 }}>SALVAR</p>
          </button>
        </Box>
      </Box>
    </Box>
  );
};