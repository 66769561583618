

import { useState, useEffect } from "react";
import { Box, MenuItem, TextField } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import api from "../../services/api";
import Select from 'react-select';
import { customStyles } from "./styles";
import { Body } from "../../components/Body";
import { useNavigate } from "react-router-dom";

interface SelectedOption {
  label: string;
  value: number;
}

export function AssignRoutine() {
  const [resetInputs, setResetInputs] = useState(false);
  const [isStationSelected, setIsStationSelected] = useState(false);
  const [routines, setRoutines] = useState<any[]>([]);
  const [allRoutines, setAllRoutines] = useState<any[]>([]);
  const [stations, setStations] = useState<any[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [deletedRoutinesArray, setDeletedRoutinesArray] = useState<any[]>([]);
  const [selectKey, setSelectKey] = useState(0);
  const navigate = useNavigate();

  const [selectedStationId, setSelectedStationId] = useState<string>('');

  async function saveAssignRoutine() {
    try {
      const stationRoutineArray = selectedOptions.map((item: SelectedOption) => ({
        stationId: selectedStationId,
        routineId: item.value,
      }));
  
      const responseStationRoutine = await api.post("/api/addStationRoutine", {
        stationRoutineArray: stationRoutineArray,
        deletedRoutinesArray: deletedRoutinesArray,
      });
  
      if (responseStationRoutine.status === 200) {
       
        setSelectedStationId('');
        setAllRoutines([]);
        setSelectedOptions([]);
        setDeletedRoutinesArray([]);
        setSelectKey((prevKey) => prevKey + 1);
        setIsStationSelected(true);
        setResetInputs((prevResetInputs) => !prevResetInputs);
  
        toast.success("Rotinas atribuídas com sucesso!");
      } else {
        toast.error("Erro ao atribuir rotinas, tente novamente!");
      }
    } catch (error: any) {
      console.error('Erro forte', error);
    }
  }

  const handleStationChange = async (station_id: string) => {
    try {
      setSelectedStationId(station_id);
      setIsStationSelected(false);

      const responseAllRoutines = await api.get<{ id: string; name: string }[]>("/api/getAllRoutine");
      const allRoutinesData = responseAllRoutines.data;

      const responseStationRoutines = await api.get<{ routine_id: string; name: string }[]>(`/api/getAllStationRoutine/${station_id}`);
      const stationRoutines = responseStationRoutines.data;

      const unlinkedRoutines = allRoutinesData.filter(
        (routine) => !stationRoutines.some((linkedRoutine) => linkedRoutine.name === routine.name)
      );

      const formattedUnlinkedRoutines = unlinkedRoutines.map((routine) => ({
        value: routine.id,
        label: routine.name,
      }));



      setRoutines(formattedUnlinkedRoutines);

      const defaultValues = stationRoutines.map((linkedRoutine) => ({
        value: linkedRoutine.routine_id,
        label: linkedRoutine.name,
      }));

      setAllRoutines(defaultValues);
      setIsStationSelected(true);

    } catch (error: any) {
      console.error("Error handling station change:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseStation = await api.get<{ id: string, name: string }[]>(`/api/getAllStation`);
        setStations(responseStation.data);
      } catch (error: any) {
        console.error('Error fetching equipment data:', error);
      }
    };

    fetchData();
  }, [resetInputs]);

  const handleSelectChange = (selectedOptions: any) => {
    const removedRoutines = allRoutines.filter(
      (routine) => !selectedOptions.some((selected: any) => selected.value === routine.value)
    );

    const deletedRoutinesArray = removedRoutines.map((routine) => ({
      stationId: selectedStationId,
      value: routine.value
    }));

    setDeletedRoutinesArray(deletedRoutinesArray);
    setSelectedOptions(selectedOptions);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: '100%',
          borderWidth: 10,
          borderColor: '#efefef',
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
          <Box sx={{ borderStyle: 'solid', borderWidth: 1, borderColor: '#111c5d', padding: 2, borderRadius: 3, width: '500px', flexDirection: 'column', position: 'relative', alignItems: 'center', justifyContent: 'center' }}>
            <span style={{ position: 'absolute', top: -14, left: 10, backgroundColor: '#FFF', padding: '0 5px', color: '#111c5d', zIndex: 1 }}>
              Atribuir Rotinas
            </span>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
              <div style={{ marginBottom: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <Box sx={{ flexDirection: 'column' }}>
                    <TextField
                      select
                      sx={{ minWidth: '466px', maxWidth: '45%' }}
                      label="Estação"
                      value={selectedStationId}
                      onChange={(e) => handleStationChange(e.target.value)}
                      size='small'
                    >
                      {stations.map((station) => (
                        <MenuItem key={station.id} value={station.id}>
                          {station.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    {isStationSelected && (
                      routines && (
                        <Select
                          key={selectedStationId}
                          isMulti
                          options={routines}
                          defaultValue={allRoutines}
                          styles={customStyles}
                          onChange={(selectedOptions) => handleSelectChange(selectedOptions)}
                          placeholder="Rotinas"
                        />
                      )
                    )}
                  </Box>
                </div>
              </div>
            </div>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <button
                onClick={saveAssignRoutine}
                type="button"
                className="btn btn-primary"
                style={{ backgroundColor: '#111c5d', width: 100, height: 30, borderRadius: 5, bottom: 10, right: 10, padding: 0, border: 'none' }}
              >
                <p style={{ fontSize: 12, margin: 0 }}>SALVAR</p>
              </button>
              <button
                onClick={() => navigate(`/details/usuario`)}
                type="button"
                className="btn btn-primary"
                style={{ backgroundColor: '#111c5d', width: 100, height: 30, borderRadius: 5, bottom: 10, right: 10, padding: 0, border: 'none' }}
              >
                <p style={{ fontSize: 12, margin: 0 }}>PRÓXIMO</p>
              </button>
            </Box>

          </Box>
        </Box>
      </Box>
    </>
  );
};