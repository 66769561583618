import { Col, Row, Container, Card, Table } from "react-bootstrap";
import { containerBoxShadow } from "../../style";
import { useEffect, useState } from "react";
import { displayPartsToString } from "typescript";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { hover } from "@testing-library/user-event/dist/hover";
import { ToastContainer, toast } from "react-toastify";
import { CheckList } from "../Checklist";
import { Box, MenuItem, Select } from "@mui/material";
import TextField from '@mui/material/TextField';
import { useForm, Controller } from 'react-hook-form';
import { Routine } from "../../pages/Routine";
import { Activity } from "../../pages/Activity";
import { Station } from "../../pages/Station";
import { Technical } from "../../pages/Technical";
import { Equipment } from "../../pages/Equipment";
import { AssignRoutine } from "../../pages/AssignRoutine";
import { Agendamentos2 } from "../../pages/Agendamentos2";
import {  Mapas } from "../../pages/Mapas";


interface RegisterProps {
  labels: string[];
  tittle: string;
}

export const Register: React.FC<RegisterProps> = ({ labels, tittle }) => {

  //objeto para criação de labels nas listas
  type Data = { [key: string]: any };
  const initialData: Data = {};
  labels?.forEach((label) => {
    initialData[label] = "";
  });

  //listas com tipos e estações para seleção no cadastro do equipamento
  const [types, setTypes] = useState<any[]>([{ id: 0, name: "Selecione" }]);
  const [stations, setStations] = useState<any[]>([{ id: 0, name: "Selecione" }]);
  const [routines, setRoutines] = useState<any[]>([{ id: 0, name: "Selecione" }]);
  const [routineStation, setRoutineStation] = useState<any[]>([{ id: 0, name: "Selecione" }]);
  const [itemsCheck, setItemsCheck] = useState<string[]>([]);
  const [resetFields, setResetFields] = useState(false);
  const [coord, setCoord] = useState<{ latitude: string, longitude: string }>({ latitude: '', longitude: '' });

  const { register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors } } = useForm();

  const selectedStation = watch("estacao")


  let routes = "";
  if (tittle === "Cadastro equipamentos") {
    routes = "addEquipment";
  } else if (tittle === "Cadastro usuário") {
    routes = "addTechnical";
  }




  //função para armazenar selects de etipo e estacao
  const handleInputChange = (e: any, label: string) => {
    let estacao = []
    let tipo = []
    let rotina = []
    let rotinas = []
    if (label === "estacao") {
      estacao = stations.filter((item) => item.id == e)
      //setData({ ...data, [label]: estacao[0] });
    } else if (label === "tipo") {
      tipo = types.filter((item) => item.id == e)
      // setData({ ...data, [label]: tipo[0] });
    } else if (label === "rotina") {
      rotina = routines.filter((item) => item.id == e)
      // setData({ ...data, [label]: e });
    } else if (label === "rotinas") {
      rotinas = routineStation.filter((item) => item.id == e)
    }
  };

  //funcao para salvar registro

  const onSubmit = (data: any) => {
    registrar(data)
    setResetFields(!resetFields)
    reset()
  };
  async function registrar(data: any) {
    data.checkList = itemsCheck
    let cont = 0;
    labels.map((item) => {
      data[item] === "" && cont++;
    });

    if (tittle == "equipamento") {
      data.estacao.latitude = coord.latitude
      data.estacao.longitude = coord.longitude
    }


    if (cont === 0) {
      try {
        const response = await api.post(`/api/${routes}`, { data });
        setItemsCheck([]);
        toast.success("Cadastro Realizado");
      } catch (error) {
        toast.error("Tente novamente!");
        console.log(error);
      }
    } else {
      toast.warn(`Verifique ${cont} campos vazios.`);
    }
  }

  //buscar lista de estações e tipos
  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (tittle == "equipamento") {
  //       const responseStation = await api.get(`/api/getAllStation`);
  //       const responseTypes = await api.get(`/api/getAllTypes`);

  //       setStations([...stations, ...responseStation.data]);
  //       setTypes([...types, ...responseTypes.data]);
  //     }
  //     const responseStation = await api.get(`/api/getAllStation`);
  //     const responseTypes = await api.get(`/api/getAllTypes`);
  //     const responseRoutines = await api.get(`/api/getAllRoutine`);
  //     setRoutines([...routines, ...responseRoutines.data])
  //   };

  //   fetchData();
  // }, [resetFields]);

  const navigate = useNavigate();


  function changeCoords(e: any, item: string) {
    let cordenadas: { latitude: string; longitude: string } = { latitude: '', longitude: '' }
    if (item === "latitude") {
      cordenadas.latitude = e
      cordenadas.longitude = coord.longitude
    } else {
      cordenadas.longitude = e
      cordenadas.latitude = coord.latitude
    }
    setCoord(cordenadas)
  }

  useEffect(() => {
    let cordenadas: { latitude: string; longitude: string } = { latitude: '', longitude: '' }
    if (selectedStation !== undefined) {

      cordenadas.longitude = selectedStation.longitude?.toString()
      cordenadas.latitude = selectedStation.latitude?.toString()

    }
    setCoord(cordenadas)


  }, [selectedStation]);

  return (

    <Box
      sx={{
        marginTop: "10px",
        justifyContent: 'space-between',
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        borderRadius: "8px",
        width: '100%',
        maxWidth: "100%",
        padding: '20px',
        backgroundColor: "white"
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "wrap", justifyContent: "space-arround", width: "100%" }}>
        {labels?.map((item, index) => {
          return (
            item == "estacao" || item === "tipo" || item === "rotina" ?
              <TextField
                select
                key={`${index}${item}`}
                defaultValue={
                  item === 'estacao' ? stations[0] :
                    item === 'rotina' ? routines[0] : types[0]
                }
                //value={item == 'estacao' ? stations[0] : types[0]}
                sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: '20px' }}
                label={`${item?.charAt(0).toUpperCase()}${item?.slice(1)}`}
                size='small'
                {...register(item, {
                  validate: (value) => {
                    return value !== '0'
                  }
                })}
              >
              </TextField>

              : item === "checkList" ?
                <CheckList key={"check"} itemsCheck={itemsCheck} setItemsCheck={setItemsCheck} />

                : item === "checkRoutine" ?
                  <Routine key={"check"} itemsCheck={itemsCheck} setItemsCheck={setItemsCheck} />

                  : item === "checkActivity" ?
                    <Activity key={"check"} itemsCheck={itemsCheck} setItemsCheck={setItemsCheck} />

                    : item === "station" ?
                      <Station key={"check"} itemsCheck={itemsCheck} setItemsCheck={setItemsCheck} />

                      : item === "technical" ?
                        <Technical key={"check"} itemsCheck={itemsCheck} setItemsCheck={setItemsCheck} />


                        : item === "equipment" ?
                          <Equipment key={"check"} itemsCheck={itemsCheck} setItemsCheck={setItemsCheck} />

                          : item === "agendamentos" ?
                          <Agendamentos2 key={`${index}${item}`}/>


                          : item === "latitude" || item === "longitude" ?
                            <TextField id="outlined-basic"
                              key={`${index}${item}`}
                              size='small'
                              label={`${item?.charAt(0).toUpperCase()}${item?.slice(1)}`}
                              value={coord.latitude !== "" && item == "latitude" ? coord.latitude
                                : coord.longitude && item == "longitude" ? coord.longitude : ""}
                              variant="outlined"
                              error={!!errors?.[item]}
                              onChange={(e) => changeCoords(e.target.value, item)}
                              sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: "20px" }}

                            />


                            : <TextField id="outlined-basic"
                              key={`${index}${item}`}
                              size='small'
                              label={`${item?.charAt(0).toUpperCase()}${item?.slice(1)}`}
                              variant="outlined"
                              error={!!errors?.[item]}
                              {...register(item, { required: true })}
                              sx={{ minWidth: '400px', maxWidth: '45%', marginBottom: "20px" }}
                            />


          )
        }
        )}

      </form>

    </Box>





  )


};
