import React, { useEffect, useState } from "react";
import iconWaterBox from "../../assets/images/waterBox.png";
import iconTechnicals from "../../assets/images/technical.png";
import iconBuilding from "../../assets/images/station.png";
import { imageSize, tollbarStyles } from "../../style";
import { Menu } from "../Menu";
import { Register } from "../Register";
import { Avatar, Box, Button, TextField } from "@mui/material";
import logoNext from '../../assets/images/Nextage.png';
import logoNextA from '../../assets/images/taskviewLogo.svg'
import Stack from '@mui/material/Stack';
import api from "../../services/api";
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";


interface ToolbarProps { }

export const Toolbar: React.FC<ToolbarProps> = ({ }) => {
  const [hoveredImg, setHoveredImg] = useState<string | null>(null);
  const [image, setImage] = useState<any>(null);
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState<string | null>(null);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseImage = await api.get("/api/getAllImages/2");
        const responseData = responseImage.data[0].image

        const nextageLogoBase64 = `data:image/svg+xml;base64,${responseData}`;

        setImage(nextageLogoBase64);
      } catch (error: any) {
        console.error('Error fetching image:', error);
      }
    };

    fetchData();
  }, []);

  const handleMouseEnter = (img: string) => {
    setHoveredImg(img);
  };

  const handleMouseLeave = () => {
    setHoveredImg(null);
  };

  const logout = () => {
    localStorage.removeItem('token')
    navigate('/')
  }

  const handleMenuClick = (label: string) => {
    setSelectedItem(label);
  };


  return (
    <div style={{
      width: '300px',
      height: 'auto',
      backgroundColor: '#111c5d',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

    }}>
      <Box
        sx={{

          display: 'flex',
          maxHeight: '100px',
          marginTop: '40px',
          justifyContent:'space-between',

        }}>

        <img src={image} alt="Logo" />


      </Box>

      <Box
        sx={{
          width: '80%',
          height: "80%",
          maxHeight: '1200px',
          marginTop: '40px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
    
        <Box sx={{ height: "0px", display: "flex", flexDirection: "column", justifyContent: "space-around", marginTop: '7%' }}>
          <Menu label={'ESTAÇÃO'} to="/details/estacao" />
            <Menu label={'EQUIPAMENTOS'} to="/details/equipamentos" />
            <Menu label={'ATIVIDADES'} to="/details/atividades" />
            <Menu label={'ROTINAS'} to="/details/rotinas" />
            <Menu label={'USUÁRIO'} to="/details/usuario" />
            <Menu label={'ATRIBUIR  ROTINAS'} to="/details/atribuir%20rotinas" />
            <Menu label={'ATRIBUIR ATIVIDADES '} to="/details/atribuir%20atividades" />
            <Menu label={'MAPAS'} to="/details/Mapas" />
            <Menu label={'HISTÓRICO'} to="/details/Historico" />
            <Menu label={'AGENDAMENTOS'} to="/details/agendamentos" />
        </Box>


      </Box>

      <Box style={{ padding: "20px 0px", display: 'flex', alignItems: "center", width: '85%', justifyContent: 'space-around' }}>
        <Avatar alt="perfil photo" src={logoNext} />
        <p style={{ marginTop: '13px', color: "white" }}>Administrador</p>
      </Box>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          cursor: 'pointer',
          width: '100%',
          marginBottom: '20px'
        }}
        onClick={logout}
      >

        <LogoutIcon style={{ marginRight: '8px' }} />
        <div>Sair</div>
      </Box>

    </div>

  );
};

