import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Body } from "../../components/Body";
import Select from 'react-select';
import {
  Paper,
  Box,
  Button,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import Modal from "react-bootstrap/Modal";
import api from "../../services/api";
import { toast } from "react-toastify";
import QRCode from "qrcode.react";
import { EditForm } from "../EditForm";
import { customStyles } from "../../pages/AssignRoutine/styles";
import { ModalEquipament } from "../../pages/ModalEquipament";

interface ListProps {
  labels: string[];
  tittle: string;
}

interface EquipmentData {
  scheduling_id: number;
  // adicione outros campos conforme necessário
}

export const List: React.FC<ListProps> = ({ labels, tittle }) => {
  const [data, setData] = useState<any[]>([]);
  const [itemData, setItemData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [routines, setRoutines] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [labelArray, setLabelArray] = useState<string[]>([]);
  const [stations, setStations] = useState<any[]>([]);
  const [technicals, setTechnical] = useState<any[]>([]);
  const [schedulingId, setSchedulingId] = useState<string>('');
  const [equipamentId, setEquipamentId] = useState<string>('');
  const [activities, setActivities] = useState<any[]>([]);
  const [equipments, setEquipments] = useState<{ id: string; name: string }[]>([]);
  const [equipmentType, setEquipmentType] = useState<{ id: string; name: string }[]>([]);
  const [activityType, setActivityType] = useState<{ id: string; name: string }[]>([]);
  const [pdfUrl, setPdfUrl] = useState<string>('');
  const [equipmentId, setEquipmentId] = useState<any>('');
  const [showModal, setShowModal] = useState(false);
  const [showModalls, setShowModalls] = useState(false);
  const [selectedStations, setSelectedStations] = useState<any[]>([]);
  const [selectedTechnical, setSelectedTechnical] = useState<any[]>([]);
  const [selectedActivities, setSelectedActivities] = useState<any[]>([]);
  const [selectedRoutines, setSelectedRoutines] = useState<any[]>([]);
  let route = "";

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch();
    }
  };

  const formatDateTime = (dateTimeString: string | number | Date) => {
    if (!dateTimeString) {
        // Retorne uma string vazia ou outro valor padrão para campos sem data
        return "";
    }

    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    };

    const dateTime = new Date(dateTimeString);
    return dateTime.toLocaleString('pt-BR', options);
};

  
  const handleFilter = async () => {
    try {
      const response = await api.post('/api/getAllDataFromRoutine', {
        stations: selectedStations,
        technicals: selectedTechnical,
        routines: selectedRoutines,
      });
      console.log("🚀 ~ handleFilter ~ response: esta chegando ", response)
  
      const filteredData = response.data;
      const formattedData = filteredData.map((item: {
        station_name: any;
        routine_name: any;
        technical_name: any;
        activity_name: any;
        activity_type: any;
        type: any;
        status: any;
        completion_date: any;
        weekday_name: any;
        scheduling_id: any;
        routine_id: any;
        station_id: any;
        technical_id: any;
        equipment: any;
      }) => ({
        Estação: item.station_name,
        Rotina: item.routine_name,
        Atividade: item.activity_name,
        "Tipo Atividade": item.activity_type,
        type: item.type,
        Técnico: item.technical_name,
        "Dia/Semana": item.weekday_name,
        "Data/Realização": formatDateTime(item.completion_date),
        Status: item.status,
        Ações: "Ações",
        scheduling_id: item.scheduling_id,
        routine_id: item.routine_id, // Adding these fields for later use
        station_id: item.station_id,
        technical_id: item.technical_id,
        equipment: item.equipment
      }));
  
      setData(formattedData); // Store the formatted data in state
    } catch (error) {
      console.error('Error filtering data:', error);
    }
  };
  
  const handleVisualizarClick = (routine_id: number, station_id: number, technical_id: number) => {
    const selectedData = data.find((item) => 
      item.routine_id === routine_id && 
      item.station_id === station_id && 
      item.technical_id === technical_id
    );
    if (selectedData) {
      navigate(`/homeDetails/${selectedData.routine_id}/${selectedData.station_id}/${selectedData.technical_id}`, { state: [selectedData] });
    }
  };
  
  const handleVisualizarEquipamentos = (routine_id: number, station_id: number, technical_id: number, scheduling_id: number, weekday_id: number) => {
    const selectedData = data.find((item) => 
      item.routine_id === routine_id && 
      item.station_id === station_id && 
      item.technical_id === technical_id &&
      item.scheduling_id === scheduling_id &&
      item.weekday_id === weekday_id 
    );
  
    if (selectedData) {
      setSchedulingId(selectedData.scheduling_id || selectedData.id);
      setEquipamentId(selectedData.equipment_id || selectedData.equipment) // Use the correct data field
      setShowModalls(true); // Assuming this controls the modal visibility
    }
  };

// const handleVisualizarEquipamentos = (scheduling_id: number) => {
//   // Encontre o item com o scheduling_id correspondente
//   const selectedData = data[0].id;

//   // Log para verificar o que foi encontrado
//   console.log("🚀 ~ handleVisualizarEquipamentos ~ selectedData ~~~~~~~~~~~~~~~~~~~~~~:", selectedData);

//   // Atualize o estado para mostrar o modal ou realizar outra ação
//   if (selectedData) {
//     // Realize ações com selectedData aqui, como exibir um modal
//     setShowModalls(true);
//   } else {
//     // Caso o item não seja encontrado, você pode querer lidar com isso
//     console.warn("Item com scheduling_id não encontrado:", scheduling_id);
//   }
// };


  useEffect(() => {
    if (tittle === "equipamentos") {
      route = "getAllEquipmentsFront";
      const inputName: string[] = ["Equipamento", "Código", "Latitude", "Longitude", "Tipo", "Estação", "Endereço", "Observações", "Curiosidades"]
      setLabelArray(inputName)
    } else if (tittle === "usuario") {
      route = "getAllTechnicalFront";
      const inputName: string[] = ["Usuário", "Email", "Estação"]
      setLabelArray(inputName)
    } else if (tittle === "estacao") {
      route = "getAllStationFront";
      const inputName: string[] = ["Estação", "Endereço", "Latitude", "Longitude"]
      setLabelArray(inputName)
    } else if (tittle === "rotinas") {
      route = "getAllRoutineFront";
      const inputName: string[] = ["Rotina"]
      setLabelArray(inputName)
    } else if (tittle === "atividades") {
      route = "getAllActivitiesFront";
      const inputName: string[] = ["Atividade", "Equipamento", "Tipo"]
      setLabelArray(inputName)
    } else if (tittle === "agendamentos") {
      route = "getSchedulingFront";
      const inputName: string[] = ["Estação", "Rotina", "Atividade", "Tipo/Atividade", "Técnico", "Dia/Semana", "Data/Realização", "Status"]
      setLabelArray(inputName)
    }

    async function fetchData() {
      try {
        const responseJson = await fetch(
          `${process.env.REACT_APP_API_URL}/api/${route}`
        );
        const dataJson = await responseJson.json();
        setData(dataJson);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [tittle]);

  function removeAccents(str: string) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  useEffect(() => {
    async function handleItems() {
        try {
            const responseJson = await api.post(`/api/getItemData/${selectedItem}/${removeAccents(tittle)}`);
            const dataJson = responseJson.data;
            setItemData(dataJson);

            switch (tittle) {
                case 'equipamentos': {
                    const responseStation = await api.get<{ id: string; name: string }[]>("/api/getAllStation");
                    const responseEquipmentType = await api.get<{ id: string; name: string }[]>("/api/getAllTypes");

                    setStations(responseStation.data);
                    setEquipmentType(responseEquipmentType.data);
                    break;
                }
                case 'atividades': {
                    const responseActivityType = await api.get<{ id: string; name: string }[]>("/api/getAllActivityTypes");
                    const responseEquipment = await api.get<{ id: string; name: string }[]>("/api/getAllEquipments");

                    setActivityType(responseActivityType.data);
                    setEquipments(responseEquipment.data);
                    break;
                }
                case 'usuario': {
                    const responseStation = await api.get<{ id: string; name: string }[]>("/api/getAllStation");

                    setStations(responseStation.data);
                    break;
                }
                case 'agendamentos': {
                    const responseStation = await api.get<{ id: string; name: string }[]>("/api/getAllStation");
                    const responseTechnical = await api.get<{ id: string, name: string }[]>(`/api/getAllTechnical`);
                    const responseRoutines = await api.get<{ id: string, name: string }[]>(`/api/getAllRoutine`);
                    setStations(responseStation.data);
                    setTechnical(responseTechnical.data);
                    setRoutines(responseRoutines.data);
                    //TODO Create an endpoint to return the following JSON
                    // {
                    //   "stations": [...],
                    //   "routines": [...],
                    //   "activities": [...],
                    //   "activityTypes": [...],
                    //   "technicals": [...],
                    //   "activityStatuses": [...]
                    // }
                    //Also change the handleSave to properly display the updated list with this fetched data on the useCase inside it
                    break;
                }
                default:
                    break;
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    handleItems();
}, [selectedItem, tittle]);



  const navigate = useNavigate();

  const redirectToQrCodePage = (item: any) => {
    navigate(`/qrCode/${item.Id}`, { state: { item } });
  };

  const handleSearch = async () => {
    try {
      if (searchValue != null && searchValue != '') {
        const responseJson = await api.post(`/api/dataFilter/${removeAccents(tittle)}/${searchValue}`);
        setData(responseJson.data);
      } else {
        if (tittle === "equipamentos") {
          route = "getAllEquipmentsFront";
          const responseJson = await api.get(`/api/getAllEquipmentsFront`);
          setData(responseJson.data)
        } else if (tittle === "usuario") {
          route = "getAllTechnicalFront";
          const responseJson = await api.get(`/api/getAllTechnicalFront`);
          setData(responseJson.data)
        } else if (tittle === "estacao") {
          route = "getAllStationFront";
          const responseJson = await api.get(`/api/getAllStationFront`);
          setData(responseJson.data)
        } else if (tittle === "rotinas") {
          route = "getAllRoutineFront";
          const responseJson = await api.get(`/api/getAllRoutineFront`);
          setData(responseJson.data)
        } else if (tittle === "atividades") {
          route = "getAllActivitiesFront";
          const responseJson = await api.get(`/api/getAllActivitiesFront`);
          setData(responseJson.data)
        }else if (tittle === "agendamentos") {
          route = "getSchedulingFront";
          const responseJson = await api.get(`/api/getSchedulingFront`);
          setData(responseJson.data)
        }
      }
    } catch (error) {
      console.error("Error searching data:", error);
    }
  };

  
  const handleEditClick = (item: any) => {
    setSelectedItem(item);
    setIsEditModalOpen(true);
  };

  const handleDeleteItem = async (item: any) => {
    try {
      const responseJson = await api.post(`/api/deleteItemData/${item}/${removeAccents(tittle)}`);
      deleteData(item)
    } catch (error) {
      toast.error("O item não pode ser excluido pois ele está sendo referenciado à outro item!")
    }
  };

  const deleteData = (selectedItem: any) => {
    let index = 0
    let originalObj: any[] = data.filter((item: any) => item.id !== selectedItem)
    setData(originalObj)
  }

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedItem(0);
  };

  const handleSave = async (updatedData: any) => {
    const responseJson = await api.post(`/api/updateItemData/${selectedItem}/${removeAccents(tittle)}`, updatedData);

    const updateField = (fieldName: string, items: any[], key: string) => {
        const item = items.find(item => item.id === updatedData[fieldName]);
        updatedData[fieldName] = item?.[key] || updatedData[fieldName];
    };

    switch (tittle) {
        case 'equipamentos':
            if (updatedData['Estação']) {
              updateField('Estação', stations, 'name');
            } 
            if (updatedData['Tipo']) {
              updateField('Tipo', equipmentType, 'name');
            }
            break;
        case 'atividades':
            if (updatedData['Tipo']) {
              updateField('Tipo', activityType, 'name');
            } 
            if (updatedData['Equipamento']) {
              updateField('Equipamento', equipments, 'name');
            }
            break;
        case 'usuario':
              if (updatedData['Estação']) {
                updateField('Estação', stations, 'name');
              }
            break;
        case 'agendamentos':
            if (updatedData['Estação']) {
              updateField('Estação', stations, 'name');
            } 
            break;
        default:
            break;
    }

    changeData(updatedData, selectedItem);
  };
  

  const changeData = (updatedData: any, selectedItem: any) => {
    setData((prevData) => {
      const newData = prevData.map((item) => {
        if (item.id === selectedItem) {
          return { ...item, ...updatedData };
        }
        return item;
      });
      return newData;
    });
    handleCloseEditModal();
  };

  function convertBase64ToPdf(base64: string) {


    // Converter Base64 para Blob
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Criar URL para o Blob
    const blobUrl = URL.createObjectURL(blob);

    // Atualizar o estado com a URL do Blob
    setPdfUrl(blobUrl);

    // Libera a URL do Blob quando o componente é desmontado
    return () => URL.revokeObjectURL(blobUrl);
  }

  const handleSearchEquipmentPdf = async (equipmentId: number) => {
    setEquipmentId(equipmentId);
    try {
      const response = await api.get(`/api/getPdfEquipmentId/${equipmentId}`);
      const documentBase64 = response.data.pdf;
      convertBase64ToPdf(documentBase64);
      setShowModal(true);
    } catch (error) {
      console.error('Erro ao buscar Obs e Curiosidades:', error);
    }
  };

  const qrCodeContent = `${process.env.REACT_APP_QR_CODE_URL}/qrcodeInfos/${equipmentId}`;

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'Dentro dos Padrões':
        return <CheckCircleIcon sx={{ color: '#14a105' }} />;
      case 'Fora dos Padrões':
        return <CancelIcon sx={{ color: '#e11717' }} />;
      case 'Indisponível':
        return <ReportProblemIcon sx={{ color: '#ffca02' }} />;
      case 'Pendente':
        return <SyncProblemIcon sx={{ color: 'orange' }} />;
      default:
        return null;
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'Dentro dos Padrões':
        return 'Dentro dos Padrões';
      case 'Fora dos Padrões':
        return 'Fora dos Padrões';
      case 'Indisponível':
        return 'Indisponível';
      case 'Pendente':
        return 'Pendente';
      default:
        return '';
    }
  };
  
  

  return (
    <Box sx={{ width: "100%" }}>

       {/* Render different search components based on the title */}
       {tittle === "agendamentos" ? (
       <Box sx={{ display: 'flex', gap: '5px', marginTop: 2, width: '100%' }}>
       <Select
           isMulti
           styles={customStyles}
           options={stations.map((station) => ({ value: station.id, label: station.name }))}
           placeholder={"Estação"}
           onChange={(selectedOptions) => setSelectedStations(selectedOptions.map((option) => option.value))}
       />
       <Select
           isMulti
           styles={customStyles}
           options={technicals.map((technical) => ({ value: technical.id, label: technical.name }))}
           placeholder={"Técnico"}
           onChange={(selectedOptions) => setSelectedTechnical(selectedOptions.map((option) => option.value))}
       />
        <Select
            isMulti
            styles={customStyles}
            options={routines.map((routine) => ({ value: routine.id, label: routine.name }))}
            placeholder={"Rotina"}
            onChange={(selectedOptions) => setSelectedRoutines(selectedOptions.map((option) => option.value))}
        />
       <Button
           sx={{
               height: '38px',
               backgroundColor: '#111c5d',
               ':hover': {
                   backgroundColor: '#122079',
               }
           }}
           variant="contained"
           size="small"
           type="button"
           className="btn btn-primary"
           onClick={() => handleFilter()}
       >
           Filtrar
       </Button>
   </Box>
      ) : (
        <Paper
        component="form"
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          marginBottom: '20px',
        }}
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch();
        }}
      >
    
        
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Pesquisa"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={handleKeyPress}
        />
        <IconButton
          type="button"
          sx={{ p: '10px' }}
          aria-label="search"
          onClick={handleSearch}
        >
          <SearchIcon />
        </IconButton>
      </Paper>
      )}

          
      
      
<TableContainer component={Paper} sx={{ maxHeight: '50vh', marginTop: 5, marginBottom: 2, width: '100%' }}>
  <Table size="medium" stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          {labels.map((label) => (
            <TableCell key={label}>{label}</TableCell>
          ))}
           {tittle != 'agendamentos' && (  
            <TableCell key={'Ação'}>{'Ação'}</TableCell>
            
              )}


          {tittle === 'equipamentos' && (
            <TableCell key={'QrCode1'}>{'QR Code'}</TableCell>
            
          )}
            
          {tittle === 'agendamentos' && (
            <><TableCell key={'Equipamentos'}>{'Equipamentos'}</TableCell>
            <TableCell key={'Excluir'}>{'Excluir'}</TableCell></>
          )}

        </TableRow>
      </TableHead>
      <TableBody>
        {Array.isArray(data) && data.length !== 0 ? (
          data.map((item: any, index) => (
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              key={item.id}
            >
                {labels.map((value) => (
             <TableCell key={value}>
             {value === 'Status' ? (
               <div style={{ display: 'flex', alignItems: 'center' }}>
                 {getStatusIcon(item.Status)}
                 <span style={{ marginLeft: '8px' }}>{getStatusText(item.Status)}</span>
               </div>
             ) : (
               item[value]
             )}
           </TableCell>
              ))}
              {tittle != 'agendamentos' && (
              <TableCell key={`Mapa${index}`}>
            
           
                <div style={{ display: 'flex' }}>
                  <div>

                    <Button
                      style={{ width: 'auto', height: 'auto' }}
                      onClick={() => handleEditClick(item.id)}
                    >
                      <EditIcon style={{ color: "#111c5d" }} />
                    </Button>
                  </div>
                  <div>
                  <Button style={{ width: 'auto', height: 'auto' }} onClick={() => handleDeleteItem(item.id)}>
                  <DeleteIcon style={{ color: "red" }} /> {/* Set the color to red */}
                </Button>

                  </div>
                </div>
                
              </TableCell>
                )}
              {tittle === 'equipamentos' && (
                <TableCell key={`QRCode${index}`}>
                  <Button
                    sx={{ height: '30px', backgroundColor: '#111c5d' }}
                    variant={'contained'}
                    size='small'
                    type='button'
                    className='btn btn-primary'
                    onClick={() => handleSearchEquipmentPdf(item.id)}
                  >
                    Gerar
                  </Button>
                </TableCell>
              ) } 
           {tittle === 'agendamentos' && (
              <TableCell key={`Mapa${index}`} >
                {item.activity_type_id === 2 || item.type === "2" ? (
                  <Button
                    sx={{ height: '30px', backgroundColor: '#111c5d' }}
                    variant={'contained'}
                    size='small'
                    type='button'
                    className='btn btn-primary'
                    onClick={() => handleVisualizarEquipamentos(item.routine_id, item.station_id, item.technical_id, item.scheduling_id, item.weekday_id)}
                  >
                    Equipamentos
                  </Button>
                ) : (
                  <div
                  style={{ width: '100px', textAlign: 'center' }}
                  />
                )}
              </TableCell>
                
            )}
            {tittle === 'agendamentos' && (
             <TableCell key={`${tittle}${index}`}>
                
              
                <div style={{ display: 'flex' }}>
                  <div>
                  <Button style={{ width: 'auto', height: 'auto' }} onClick={() => handleDeleteItem(item.id)}>
                  <DeleteIcon style={{ color: "red" }} /> {/* Set the color to red */}
                </Button>
                  </div>
                </div>
              </TableCell>
               )}

            </TableRow>
            
          ))
        ) : (
          <TableRow>
          <TableCell colSpan={labels.length + 2} style={{ textAlign: 'center' }}>
            {tittle === 'agendamentos' ? (
              <>
                Para aparecer os dados, você deve realizar o fluxo de cadastro. Para isso, clique no botão "Iniciar Fluxo" e cadastre uma estação.
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                  <Button
                    variant="contained"
                    sx={{
                      height: '38px',
                      backgroundColor: '#111c5d',
                      ':hover': {
                        backgroundColor: '#122079',
                      },
                    }}
                    onClick={() => navigate(`/details/estacao`)}
                  >
                    Iniciar Fluxo
                  </Button>
                </Box>
              </>
            ) : (
              <>
              A tabela não contém dados
              </>
            )}
          </TableCell>
        </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>


  



  <Modal show={isEditModalOpen} onHide={handleCloseEditModal}>
    <Modal.Header closeButton>
      <Modal.Title>Editar {tittle}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <EditForm items={selectedItem} labels={labelArray} title={tittle} itemData={itemData} onSave={handleSave} />
    </Modal.Body>
  </Modal>

  <Modal show={showModal} onHide={() => setShowModal(false)} backdrop={false}
    style={{ backdropFilter: 'blur(2px)' }} centered>
    <Modal.Header closeButton>
      <Modal.Title>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <QRCode value={qrCodeContent} size={200} />
    </Modal.Body>
  </Modal>
  <Modal show={showModalls} onHide={() => setShowModalls(false)}>
              <Modal.Header closeButton>
                  <Modal.Title>Equipamentos</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <ModalEquipament data={{ schedulingId: schedulingId, equipamentId: equipamentId }} />
        </Modal.Body>
    </Modal>
</Box>
  );
};

function formatDateTime(date: any) {
  throw new Error("Function not implemented.");
}

