import { useEffect, useState } from 'react';
import './styles.css';
import api from '../../services/api';

export function EquipmentInfos() {
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  useEffect(() => {
    const urlParts = window.location.href.split('/');
    const equipmentId = urlParts[urlParts.length - 1];
    handlePdfByEquipmentId(equipmentId);
  }, []);

  function convertBase64ToPdf(base64: string) {
    
    // Converter Base64 para Blob
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Criar URL para o Blob
    const blobUrl = URL.createObjectURL(blob);

    // Atualizar o estado com a URL do Blob
    setPdfUrl(blobUrl);

    // Libera a URL do Blob quando o componente é desmontado
    return () => URL.revokeObjectURL(blobUrl);
  }

  const handlePdfByEquipmentId = async (equipmentId: string) => {
    try {
      const response = await api.get(`/api/getPdfEquipmentId/${equipmentId}`);
      const documentBase64 = response.data.pdf;
      convertBase64ToPdf(documentBase64);
    } catch (error) {
      console.error('Erro ao buscar Obs e Curiosidades:', error);
    }
  }

  return (
    <>
      {pdfUrl ? (
        <iframe src={pdfUrl} width="100%" height="1000px" />
      ) : (
        <p>Carregando PDF...</p>
      )}
    </>
  );
}
